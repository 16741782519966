import React, {useEffect} from 'react';

import Login from './Login/Login';
// import SiteEngHome from './SiteEngHome/SiteEngHome';
import TabNav from './TabNav';
import OrderMaterials from './OrderMaterials/OrderMaterials';
import ProjectDetails from './ProjectDetails/ProjectDetails';
import OrderDetails from './OrderDetails/OrderDetails';
import MaterialReceived from './OrderDetails/MaterialReceived';
// import TaskDetails from './ToDo/TaskDetails';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {NavigationContainer} from '@react-navigation/native';
import ImageGallery from './OrderDetails/ImageGallery';
import TrackOrder from './OrderDetails/TrackOrder';
import ChooseMaterials from './OrderMaterials/ChooseMaterials';
import SplashScreen from './BasicComponents/SplashScreen';
import AllRequests from './ProjectDetails/AllRequests';
import HelpScreens from './HelpScreens/HelpScreens';

const Stack = createNativeStackNavigator();

export const navigationRef = React.createRef();

export function navigate(name, params) {
  navigationRef.current?.navigate(name, params);
}

function AppView({navigate}) {
  const screenHeader = (title, greyBg) => ({
    title: title,
    headerTitleStyle: {
      fontFamily: 'Gilroy-Bold',
    },
    headerStyle: {
      backgroundColor: greyBg ? '#F8F8F8' : 'white',
    },
    headerTitleAlign: 'center',
    headerShadowVisible: false,
  });

  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator initialRouteName="SplashScreen">
        <Stack.Screen
          options={{headerShown: false}}
          name="SplashScreen"
          component={SplashScreen}
        />
        <Stack.Screen
          options={{headerShown: false}}
          name="Login"
          component={Login}
        />
        <Stack.Screen
          options={{headerShown: false}}
          name="Home"
          component={TabNav}
        />
        <Stack.Screen
          name="ProjectDetails"
          options={screenHeader('Project Details')}
          component={ProjectDetails}
        />
        <Stack.Screen
          name="OrderMaterials"
          options={screenHeader('Order Materials', true)}
          component={OrderMaterials}
        />
        <Stack.Screen
          name="OrderDetails"
          options={screenHeader('Order Details', true)}
          component={OrderDetails}
        />
        <Stack.Screen
          name="MaterialReceived"
          options={screenHeader('Material Received', true)}
          component={MaterialReceived}
        />
        <Stack.Screen
          name="ImageGallery"
          options={screenHeader('Images')}
          component={ImageGallery}
        />
        <Stack.Screen
          name="TrackOrder"
          options={screenHeader('Track Order', true)}
          component={TrackOrder}
        />
        <Stack.Screen
          name="chooseMaterials"
          options={screenHeader('Choose Materials', true)}
          component={ChooseMaterials}
        />
        <Stack.Screen
          name="AllRequests"
          options={screenHeader('All Requests')}
          component={AllRequests}
        />
        <Stack.Screen
          name="HelpScreens"
          options={screenHeader('User Manual')}
          component={HelpScreens}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export default AppView;
