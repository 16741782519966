import React from "react";
import { Text, Image, StyleSheet, Dimensions, View } from "react-native";
import AppView from "./native/components/AppView";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

function App() {
  return (
    <View style={{height: windowHeight}}>
     <AppView/>
    </View>
  );
}

export default App;
