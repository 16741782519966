export default {
  username: '',
  SiteEnggId: '',
  authToken: '',

  REACT_DATA_API_URL:'https://localserver.cendrol.com/procurement',
  REACT_APP_IMAGES_URL:'https://d48j7fd3ucy92.cloudfront.net/',
  REACT_APP_ENVIRONMENT:'dev',
  APP_VERSION:'v0.1.31',

  // Order Materials
  selectedMaterials: [],
};
