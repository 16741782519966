import React from 'react';
import placeholder from '../../assets/images/placeholder.png';
import {
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
} from 'react-native';

function PlaceHolder() {
  return (
    <View style={styles.parent}>
      <Image source={placeholder} style={styles.imgStyle} />
    </View>
  );
}

export default PlaceHolder;

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  parent: {
    height: windowHeight,
    width: windowWidth,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  imgStyle: {
    width: windowWidth / 2,
    resizeMode: 'contain',
    marginBottom: 35,
  },
});
