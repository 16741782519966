import React, { useState, useEffect, useCallback } from "react";
import {
  SafeAreaView,
  ScrollView,
  ActivityIndicator,
  StyleSheet,
  Text,
  Alert,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  TextInput,
  Modal,
  ToastAndroid,
  FlatList,
} from "react-native";
import axiosClientInterceptors from "../BasicComponents/Auth";
import LinearGradient from "react-native-web-linear-gradient";
import MaterialSelection from "./MaterialSelection";
import Config from "../BasicComponents/Config";

function ChooseMaterials(props) {
  const [materials, setMaterials] = useState([]);
  const [choosenMaterial, setChoosenMaterial] = useState([]);
  const [apiActive, setApiActive] = useState(true);

  useEffect(() => {
    getMaterials();
  }, []);

  function getMaterials() {
    axiosClientInterceptors({
      method: "get",
      url: `/get-material-specs?category=${props.route.params.category}`,
    })
      .then((res) => {
        setMaterials(res.data.result);
        setApiActive(false);
      })
      .catch((err) => console.log(err));
  }

  function searchMaterials(searchText) {
    setApiActive(true);
    axiosClientInterceptors({
      method: "get",
      url: `/get-material-specs?category=${props.route.params.category}&search=${searchText}`,
    })
      .then((res) => {
        setMaterials(res.data.result);
        setApiActive(false);
      })
      .catch((err) => console.log(err));
  }

  function updateData(data) {
    let dataArr = choosenMaterial;
    if (data.ticked) {
      if (
        dataArr.filter((val) => val.material_name === data.material_name)
          .length > 0
      ) {
        const index = dataArr.findIndex(
          (obj) => obj.material_name === data.material_name
        );
        dataArr[index] = data;
      } else {
        dataArr.push(data);
      }
    } else {
      if (
        dataArr.filter((val) => val.material_name === data.material_name)
          .length > 0
      ) {
        const index = dataArr.findIndex(
          (obj) => obj.material_name === data.material_name
        );
        dataArr.splice(index, 1);
      }
    }
    setChoosenMaterial(dataArr);
  }

  // const Item = useCallback(({item}) => {
  //   return (
  //     <MaterialSelection
  //       materialName={item.material_specification}
  //       uom={item.uom}
  //       ticked={false}
  //       updateData={data => updateData(data)}
  //     />
  //   );
  // }, []);

  const Item = ({ item }) => {
    return (
      <MaterialSelection
        materialName={item.material_specification}
        uom={item.uom}
        ticked={false}
        updateData={(data) => updateData(data)}
      />
    );
  };

  const renderItem = ({ item }) => <Item item={item} />;

  const validateData = () =>
    choosenMaterial.filter((val) => val.uom.length === 0).length > 0
      ? false
      : true;

  const confirmMaterials = () =>
    validateData() === true
      ? ((Config.selectedMaterials = choosenMaterial),
        props.navigation.goBack())
      : Alert.alert(
          "Incomplete Data",
          "Select Unit of Measure (UOM) for selected materials."
        );

  return (
    <SafeAreaView style={styles.parentContainer}>
      <View style={styles.parentVue}>
        <View style={styles.pickerSearchVue}>
          <TextInput
            placeholder={`Search materials in ${props.route.params.category} category`}
            style={styles.searchTxt}
            onChangeText={(text) => searchMaterials(text)}
          />
        </View>
        <FlatList
          data={materials}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          removeClippedSubviews={false}
          // keyboardShouldPersistTaps="always"
        />
        <TouchableOpacity onPress={() => confirmMaterials()}>
          <LinearGradient
            colors={["#FFE185", "#F6BA00"]}
            start={{ x: 0, y: 1 }}
            end={{ x: 1, y: 0 }}
            style={styles.gradientBtn}
          >
            <Text style={styles.btnText}>Confirm Materials</Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
      {apiActive && (
        <ActivityIndicator size="large" color="#ffcd30" style={styles.loader} />
      )}
    </SafeAreaView>
  );
}

export default ChooseMaterials;

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const styles = StyleSheet.create({
  parentContainer: {
    backgroundColor: "#F8F8F8",
    flex: 1,
  },
  parentVue: {
    marginHorizontal: 15,
    marginBottom: 15,
    backgroundColor: "white",
    flex: 1,
  },
  pickerSearchVue: {
    borderRadius: 2,
    height: 45,
    marginVertical: 15,
    marginHorizontal: 15,
    paddingHorizontal: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#F9F9F9",
  },
  searchTxt: {
    fontFamily: "Gilroy-Medium",
    fontSize: 12,
    flex: 1,
  },
  materialName: {
    fontFamily: "Gilroy-Medium",
    fontSize: 13,
  },
  materialNameRows: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: 8,
    alignItems: "center",
  },
  checkBox: {
    borderWidth: 1,
    borderColor: "#FCC200",
    height: 24,
    width: 24,
    borderRadius: 2,
    marginRight: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  gradientBtn: {
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2,
    margin: 15,
  },

  // Not needed
  btnText: {
    fontFamily: "Gilroy-Medium",
    fontSize: 13,
  },
  tickMark: {
    flex: 1,
    width: "100%",
    resizeMode: "cover",
  },
  specsVue: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: 15,
    paddingHorizontal: 5,
    backgroundColor: "#F9F9F9",
  },
  specInputDivs: {
    flex: 1,
    paddingHorizontal: 10,
  },
  specSelectLabel: {
    fontFamily: "Gilroy-Medium",
    fontSize: 12,
    marginBottom: 8,
  },
  loader: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
});
