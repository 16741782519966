import React from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import SiteEngHome from './SiteEngHome/SiteEngHome';
// import ToDo from './ToDo/ToDo';
import {Image} from 'react-native';
import PlaceHolder from './BasicComponents/PlaceHolder';

// Tab icons
import homeActive from '../assets/images/NavHome.png';
import homeInactive from '../assets/images/NavHomeInactive.png';
import todoActive from '../assets/images/NavToDo.png';
import todoInactive from '../assets/images/NavToDoInactive.png';
import projSchActive from '../assets/images/NavSch.png';
import projSchInactive from '../assets/images/NavSchInactive.png';

const Tab = createBottomTabNavigator();

function TabNav() {
  const tabOptions = (label, imgActive, imgInactive) => {
    const tabImgStyle = {
      width: 24,
      height: 24,
      marginTop: 10,
    };
    const tabLabelStyle = {
      marginBottom: 10,
      fontFamily: 'Gilroy-SemiBold',
      fontSize: 12,
    };

    return {
      headerShown: false,
      tabBarLabel: label,
      tabBarActiveTintColor: '#FCC314',
      tabBarStyle: {height: 65},
      tabBarIcon: ({focused, color, size}) =>
        focused ? (
          <Image style={tabImgStyle} source={imgActive}></Image>
        ) : (
          <Image style={tabImgStyle} source={imgInactive}></Image>
        ),
      tabBarLabelStyle: tabLabelStyle,
    };
  };

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarHideOnKeyboard: true,
        tabBarStyle: [
          {
            display: 'flex',
          },
          null,
        ],
      }}>
      <Tab.Screen
        options={tabOptions('Home', homeActive, homeInactive)}
        name="HomeScreen"
        component={SiteEngHome}
      />
      <Tab.Screen
        options={tabOptions('To-Do', todoActive, todoInactive)}
        name="todo"
        component={PlaceHolder}
      />
      <Tab.Screen
        options={tabOptions('Project Schedule', projSchActive, projSchInactive)}
        name="Project Schedule"
        component={PlaceHolder}
      />
    </Tab.Navigator>
  );
}

export default TabNav;
