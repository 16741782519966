import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Alert, ToastAndroid} from 'react-native';
import Config from './Config';
import {navigationRef} from '../../components/AppView';

const timeout = 10000; // milliseconds

const axiosClientInterceptors = axios.create({
  baseURL: Config.REACT_DATA_API_URL + Config.REACT_APP_ENVIRONMENT,
  timeout: timeout,
});

const requestHandler = async request => {
  if (Config.authToken !== '') {
    request.headers['x-auth-token'] = Config.authToken;
  } else {
    await AsyncStorage.getItem('authToken').then(item => {
      request.headers['x-auth-token'] = item;
      Config.authToken = item;
    });
  }
  console.log('Config.authToken', Config.authToken);

  return request;

  /*
  Use refresh-token to generate new access-token and refresh-token
  If access-token is null
  */
};

const responseHandler = response => {
  return response;
};

const errorHandler = async error => {
  const status = error.response ? error.response.status : null;
  if (status === 401) {
    // will loop if refreshToken returns 401
    try {
      console.log('401 Error');
      AsyncStorage.removeItem('authToken');
      Config.authToken = '';
      AsyncStorage.removeItem('partner_id');
      ToastAndroid.show(
        'Session Expired! You have been logged out. Please login again to use the app.',
        ToastAndroid.LONG,
      );
      !Config.loggedout &&
        navigationRef.current.reset({
          index: 0,
          routes: [{name: 'Login'}],
        });
      Config.loggedout = true;
    } catch (err) {
      // logout code
      console.log('errorHandlerError', err);
    }
  }

  // return Promise.reject(error);
};

axiosClientInterceptors.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(error),
);

axiosClientInterceptors.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(error),
);

/*
Or you can use name apiClient
export default apiClient or httpClient
*/

export default axiosClientInterceptors;
