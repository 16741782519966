import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  SafeAreaView,
  ScrollView,
  View,
  Modal,
  StyleSheet,
  Dimensions,
  Text,
  TouchableOpacity,
  Image,
  RefreshControl,
  ActivityIndicator,
  TextInput,
} from "react-native";
import LinearGradient from "react-native-web-linear-gradient";
import { useFocusEffect } from "@react-navigation/native";
import axiosClientInterceptors from "../BasicComponents/Auth";
import Config from "../BasicComponents/Config";

function AllRequests(props) {
  const [projectDetails, setProjectDetails] = useState([]);
  const [username, setUsername] = useState("");
  const [requests, setRequests] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [matCatList, setMatCatlist] = useState([]);
  const [matCatFilters, setMatCatFilters] = useState([]);
  const [reqStatusFilters, setReqStatusFilters] = useState([]);
  const [search, setSearch] = useState("");
  const [projStage, setProjStage] = useState("");
  const [activeTab, setActiveTab] = useState("open");
  const [allBg, setAllBg] = useState("#F6F6F6");
  const [allStatBg, setAllStatBg] = useState("#F6F6F6");
  const [filterStatus, setFilterStatus] = useState([]);

  const [refreshing, setRefreshing] = React.useState(false);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    applyFilter(true, activeTab);
  }, []);

  // useEffect(() => {
  //   applyFilter(true, activeTab);
  //   // setRequests(props.route.params.data);
  // }, []);

  useEffect(() => {
    setFilterStatus(activeTab === "open" ? openRequests : closedRequests);
  }, [activeTab]);

  useFocusEffect(
    React.useCallback(() => {
      applyFilter(true, activeTab);
    }, [])
  );

  const openRequests = [
    "New Request",
    "Approved",
    "Partially Received",
    "Material Received",
    "PO Created",
  ];
  const closedRequests = ["Closed", "Rejected"];

  const scrollRef = useRef();

  // async function getApiData() {
  //   try {
  //     axiosClientInterceptors({
  //       method: 'get',
  //       url: `/getseprojects?_id=${projectId}&site_engineer=${Config.SiteEnggId}`,
  //     }).then(response => {
  //       setProjectDetails(response.data.projects);
  //       setRequests(response.data.Requests.reverse());
  //       setShowLoader(false);
  //       setRefreshing(false);
  //       const projStageArr = response.data.projects.project_stage;
  //       setProjStage(projStageArr[projStageArr.length - 1]);
  //     });
  //   } catch {
  //     console.log(e);
  //   }
  // }

  async function applyFilter(reset, tabStatus) {
    setShowLoader(true);

    let res = reset && (setReqStatusFilters([]), setMatCatFilters([]));

    if (tabStatus === "open") {
      const statusFilter = reset
        ? openRequests
        : reqStatusFilters.length === 0
        ? openRequests
        : reqStatusFilters;
      const categoryFilter = reset ? [] : matCatFilters;
      callApi(statusFilter, categoryFilter);
    } else {
      // const statusFilter = closedRequests;
      const statusFilter = reset
        ? closedRequests
        : reqStatusFilters.length === 0
        ? closedRequests
        : reqStatusFilters;
      const categoryFilter = reset ? [] : matCatFilters;
      callApi(statusFilter, categoryFilter);
    }

    function callApi(statusFilter, categoryFilter) {
      console.log(
        "| statusFilter:",
        statusFilter,
        "| categoryFilter:",
        categoryFilter,
        "| Config.SiteEnggId:",
        Config.SiteEnggId,
        "| props.route.params.projectId:",
        props.route.params.projectId
      );
      axiosClientInterceptors
        .post(`/statusCategoryFilter`, {
          request_status: statusFilter,
          category: categoryFilter,
          site_engineer: Config.SiteEnggId,
          _id: props.route.params.projectId,
        })
        .then((response) => {
          console.log("response.data.Requests", response.data);
          setRequests(response.data.Requests);
          setShowLoader(false);
          setRefreshing(false);
        });
    }
    setFilterModalVisible(false);
  }

  useEffect(() => {
    const projectId = props.route.params.projectId;
    setShowLoader(true);
    axiosClientInterceptors({
      method: "get",
      url: `/request-material-category?_id=${projectId}`,
    }).then((response) => {
      setMatCatlist(response.data.result.Category);
    });
  }, []);

  function requestCard(req) {
    let priorityColor;
    let statusBgColor;
    let statusTextColor;
    switch (req.priority) {
      case "High":
        priorityColor = "#FE3E3E";
        break;
      case "Medium":
        priorityColor = "#FC8314";
        break;
      case "Low":
        priorityColor = "#3ADD5E";
        break;
      default:
        priorityColor = "black";
        break;
    }

    switch (req.request_status) {
      case "New Request":
        statusBgColor = "#F0F9FF";
        statusTextColor = "#3EAEFE";
        break;
      case "Approved":
        statusBgColor = "#EFFCF2";
        statusTextColor = "#3ADD5E";
        break;
      case "Rejected":
        statusBgColor = "#FFF0F0";
        statusTextColor = "#FE3E3E";
        break;
      case "PO Created":
        statusBgColor = "#FFFAE9";
        statusTextColor = "#FCC216";
        break;
      case "Material Received":
        statusBgColor = "#EFFCF2";
        statusTextColor = "#3ADD5E";
        break;
      case "Reconcile":
        statusBgColor = "#FFF0F0";
        statusTextColor = "#FE3E3E";
        break;
      case "Partially Received":
        statusBgColor = "#FFF0E5";
        statusTextColor = "#FB8C3C";
        break;
      case "Payment Done":
        statusBgColor = "#FBF0FF";
        statusTextColor = "#CC3EFE";
        break;
      case "Closed":
        statusBgColor = "#F2F2F2";
        statusTextColor = "#0E0E0E";
        break;
      default:
        statusBgColor = "#F2F2F2";
        statusTextColor = "#0E0E0E";
        break;
    }

    const status =
      req.request_status[req.request_status.length - 1].status === "PO Created"
        ? "Material Out For Delivery"
        : req.request_status[req.request_status.length - 1].status;

    async function showOrderDets(id) {
      axiosClientInterceptors
        .get(`/getrequestsbyid?_id=${id}`)
        .then((response) => {
          setShowLoader(false);
          const navData = {
            projectStage: props.route.params.project_stage,
            status: response.data.Requests.current_status,
            invoiceImgs: response.data.Requests.invoice,
            orderDets: response.data.Requests,
          };
          props.navigation.navigate("OrderDetails", navData);
        })
        .catch((err) => console.log(err));
    }

    return (
      <TouchableOpacity
        activeOpacity={0.6}
        style={styles.requestCard}
        title="orderMaterials"
        onPress={() => {
          setShowLoader(true);
          showOrderDets(req._id);
        }}
      >
        <View style={{ flexDirection: "row", width: windowWidth / 1.25 }}>
          <Text style={styles.cardText}>{req.request_id}</Text>
          <Text style={[styles.cardText, { color: priorityColor }]}>
            {req.priority} Priority
          </Text>
          <Text style={[styles.cardText, { borderRightWidth: 0 }]}>
            {/* Due On {moment(req.due_date).format('DD-MM-YYYY')} */}
            Due On {req.due_date}
          </Text>
        </View>
        <Text
          style={{
            width: "100%",
            fontFamily: "Gilroy-SemiBold",
            fontSize: 17,
          }}
        >
          {req.material_category}
        </Text>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View>
            <Text
              style={[
                styles.statusBox,
                { backgroundColor: statusBgColor, color: statusTextColor },
              ]}
            >
              {req.request_status === "PO Created"
                ? "Material Out For Delivery"
                : req.request_status}
            </Text>
          </View>
          <View style={styles.orderMaterials}>
            <Image
              style={styles.rightBtn}
              source={require("../../assets/images/RightBtn.png")}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  }

  const RequestsVue = useCallback(() => {
    return requests?.map((req) => {
      if (search !== "") {
        if (
          req.material_category.toLowerCase().includes(search) ||
          req.request_id.toLowerCase().includes(search)
        ) {
          return requestCard(req);
        }
      } else {
        return requestCard(req);
      }
    });
  }, [requests, search]);

  return (
    <SafeAreaView style={{ backgroundColor: "white", flex: 1 }}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={filterModalVisible}
        onRequestClose={() => {
          setFilterModalVisible(false);
        }}
      >
        <ScrollView
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.centeredFilterView}>
            <View style={styles.modalFilterView}>
              <View style={styles.filterHeader}>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={[
                      styles.modalInfoText,
                      { fontFamily: "Gilroy-Bold", fontSize: 18 },
                    ]}
                  >
                    Apply Filter
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    setFilterModalVisible(false);
                  }}
                >
                  <Image
                    style={{ height: 15, width: 15, bottom: 8, marginRight: 5 }}
                    source={require("../../assets/images/closeBtn.png")}
                  />
                </TouchableOpacity>
              </View>
              <Text
                style={[
                  styles.modalInfoText,
                  { flexDirection: "row", width: windowWidth / 1.1 },
                ]}
              >
                Material Category
              </Text>
              <View style={styles.filterElementsView}>
                <TouchableOpacity
                  style={[styles.filterElements, { backgroundColor: allBg }]}
                  onPress={() => (setAllBg("#FCC216"), setMatCatFilters([]))}
                >
                  <Text style={[styles.filterText, { fontSize: 14 }]}>All</Text>
                </TouchableOpacity>
                {matCatList.map((matCat) => {
                  let bg;
                  if (matCatFilters.includes(matCat)) {
                    bg = "#FCC216";
                  } else {
                    bg = "#F6F6F6";
                  }
                  const id = matCat;
                  return (
                    <TouchableOpacity
                      style={[styles.filterElements, { backgroundColor: bg }]}
                      onPress={() => {
                        var array = [];
                        if (matCatFilters.includes(id)) {
                          var arr = matCatFilters.filter((item) => item !== id);
                          setMatCatFilters(arr);
                        } else {
                          setMatCatFilters([...matCatFilters, matCat]);
                          setAllBg("#F6F6F6");
                        }
                      }}
                    >
                      <Text style={[styles.filterText, { fontSize: 14 }]}>
                        {matCat}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </View>

              <View>
                <Text
                  style={[
                    styles.modalInfoText,
                    { flexDirection: "row", width: windowWidth / 1.1 },
                  ]}
                >
                  Request Status
                </Text>
                <View style={styles.filterElementsView}>
                  <TouchableOpacity
                    style={[
                      styles.filterElements,
                      { backgroundColor: allStatBg },
                    ]}
                    onPress={() => (
                      setAllStatBg("#FCC216"), setReqStatusFilters([])
                    )}
                  >
                    <Text style={[styles.filterText, { fontSize: 14 }]}>
                      All
                    </Text>
                  </TouchableOpacity>
                  {filterStatus.map((reqStat) => {
                    let bg2;
                    if (reqStatusFilters.includes(reqStat)) {
                      bg2 = "#FCC216";
                    } else {
                      bg2 = "#F6F6F6";
                    }
                    const stat = reqStat;
                    return (
                      <TouchableOpacity
                        style={[
                          styles.filterElements,
                          { backgroundColor: bg2 },
                        ]}
                        onPress={() => {
                          if (reqStatusFilters.includes(stat)) {
                            var arr = reqStatusFilters.filter(
                              (item) => item !== stat
                            );
                            bg2 = "#FCC216";
                            setReqStatusFilters(arr);
                          } else {
                            setReqStatusFilters([...reqStatusFilters, reqStat]);
                            setAllStatBg("#F6F6F6");
                          }
                        }}
                      >
                        <Text style={[styles.filterText, { fontSize: 14 }]}>
                          {reqStat === "PO Created"
                            ? "Material Out For Delivery"
                            : reqStat}
                        </Text>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <View style={styles.filterBottom}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => (
              applyFilter(true, activeTab),
              setMatCatFilters([]),
              setReqStatusFilters([]),
              setAllBg("#F6F6F6"),
              setAllStatBg("#F6F6F6")
            )}
          >
            <Text style={styles.btnText}>Clear Filter</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              applyFilter(false, activeTab);
            }}
          >
            <LinearGradient
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 0 }}
              colors={["#FFE185", "#F6BA00"]}
              style={[
                {
                  height: 44,
                  width: 137,
                  borderRadius: 2,
                  justifyContent: "center",
                  alignItems: "center",
                },
              ]}
            >
              <Text style={styles.btnText}>Apply Filter</Text>
            </LinearGradient>
          </TouchableOpacity>
        </View>
      </Modal>
      <ScrollView
        ref={scrollRef}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <View style={styles.tabSection}>
          <TouchableOpacity
            activeOpacity={0.5}
            style={[
              styles.tabs,
              {
                borderBottomColor: activeTab === "open" ? "#FCC200" : "#E7E7E7",
              },
            ]}
            onPress={() =>
              !showLoader && (setActiveTab("open"), applyFilter(true, "open"))
            }
          >
            <Text style={styles.tabTxt}>Open Requests</Text>
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={0.5}
            style={[
              styles.tabs,
              {
                borderBottomColor:
                  activeTab === "closed" ? "#FCC200" : "#E7E7E7",
              },
            ]}
            onPress={() =>
              !showLoader &&
              (setActiveTab("closed"), applyFilter(true, "closed"))
            }
          >
            <Text style={styles.tabTxt}>Closed Requests</Text>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flex: 1,
            paddingBottom: 25,
            alignItems: "center",
          }}
        >
          <View style={styles.searchFilterVue}>
            <View style={styles.searchInputVue}>
              <TextInput
                placeholder="Search by Request Name or ID"
                onChangeText={(e) => setSearch(e.toLowerCase())}
                style={{ flex: 1, fontFamily: "Gilroy-Medium" }}
              />
            </View>
            <TouchableOpacity onPress={() => setFilterModalVisible(true)}>
              <View style={styles.filterIconBg}>
                <Image
                  style={{ height: 25, width: 25 }}
                  source={require("../../assets/images/filter.png")}
                />
              </View>
            </TouchableOpacity>
          </View>

          <RequestsVue />

          {/* {requests.map(req => {
            console.log('req.request_id', req.request_id);
            if (search !== '') {
              if (
                req.material_category.toLowerCase().includes(search) ||
                req.request_id.toLowerCase().includes(search)
              ) {
                return requestCard(req);
              }
            } else {
              return requestCard(req);
            }
          })} */}

          {!showLoader && requests.length === 0 && (
            <Image
              style={styles.noData}
              source={require("../../assets/images/noReq.png")}
            />
          )}
        </View>
      </ScrollView>
      {showLoader && (
        <ActivityIndicator size="large" color="#ffcd30" style={styles.loader} />
      )}
    </SafeAreaView>
  );
}

export default AllRequests;

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const styles = StyleSheet.create({
  card: {
    marginTop: 35,
    margin: 20,
    width: windowWidth / 1.1,
    backgroundColor: "#F8F8F8",
    borderRadius: 5,
  },
  cardRows: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomColor: "#EAEAEA",
    borderBottomWidth: 1,
    paddingTop: 20,
    paddingBottom: 20,
    marginLeft: 22,
    marginRight: 22,
  },
  lableText: {
    color: "#a1a1a1",
    fontFamily: "Gilroy-Medium",
    fontSize: 16,
  },
  cardText: {
    fontFamily: "Gilroy-Medium",
    fontSize: 13,
    borderRightWidth: 0.8,
    borderColor: "black",
    paddingRight: 8,
    marginRight: 8,
  },
  infoText: {
    color: "black",
    fontFamily: "Gilroy-SemiBold",
    fontSize: 16,
  },
  noOf: {
    fontFamily: "Gilroy-Medium",
    fontSize: 12,
    color: "#969696",
  },
  buttons: {
    width: 137,
    height: 38,
    borderRadius: 3,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
  },
  rightBtn: {
    height: 20,
    width: 20,
    padding: 3,
  },
  requestCard: {
    justifyContent: "space-between",
    width: windowWidth / 1.1,
    minHeight: 135,
    marginHorizontal: 20,
    marginVertical: 10,
    padding: 18,
    alignItems: "center",
    borderRadius: 4,
    backgroundColor: "#FAFAFA",
  },
  status: {
    padding: 8,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 2,
  },
  loader: {
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    bottom: windowHeight / 2,
  },
  statusBox: {
    color: "black",
    fontFamily: "Gilroy-SemiBold",
    paddingVertical: 10,
    paddingHorizontal: 14,
    backgroundColor: "#EFFCF2",
    color: "#3ADD5E",
  },
  searchIcon: {
    color: "black",
    fontSize: 30,
    margin: 10,
  },
  health: {
    paddingVertical: 10,
    paddingHorizontal: 14,
    borderRadius: 3,
    justifyContent: "center",
    maxWidth: windowWidth / 3.4,
  },
  modalFilterView: {
    backgroundColor: "white",
    // borderRadius: 20,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 25,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: windowWidth,
    minHeight: windowHeight / 2,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: windowWidth / 1.1,
  },
  modalText: {
    marginBottom: 25,
    textAlign: "center",
    color: "black",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  centeredFilterView: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    // paddingTop: windowHeight / 2,
    marginTop: windowHeight / 2,
  },
  modalButton: {
    // backgroundColor: '#ff4040',
    marginTop: 25,
    height: 45,
    width: windowWidth / 3,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  filterHeader: {
    width: windowWidth / 1.1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 25,
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "#DEDEDE",
  },
  filterElements: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: "#F6F6F6",
    justifyContent: "center",
    borderRadius: 3,
    marginVertical: 10,
    marginHorizontal: 4.5,
  },
  filterElementsView: {
    width: windowWidth / 1.1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    borderBottomWidth: 1,
    borderColor: "#DEDEDE",
    paddingBottom: 10,
    marginBottom: 15,
  },
  button: {
    backgroundColor: "#ffffff",
    borderRadius: 3,
    borderWidth: 1,
    borderColor: "#EAEAEA",
    width: 137,
    height: 44,
    justifyContent: "center",
    alignItems: "center",
  },
  filterBottom: {
    width: windowWidth,
    flexDirection: "row",
    justifyContent: "space-around",
    paddingVertical: 22,
    backgroundColor: "white",
    elevation: 10,
  },
  modalInfoText: {
    color: "black",
    fontFamily: "Gilroy-Bold",
    fontSize: 16,
    marginBottom: 10,
  },
  noData: {
    width: 291,
    height: 253,
    alignSelf: "center",
    justifyContent: "center",
    marginTop: 50,
  },
  filterText: {
    color: "black",
    fontFamily: "Gilroy-Medium",
    fontSize: 16,
  },
  vueMoreLess: {
    fontFamily: "Gilroy-Medium",
    fontSize: 14,
  },
  viewAllVue: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    marginHorizontal: 16,
    marginTop: 5,
    marginBottom: -10,
    borderTopWidth: 1,
    borderColor: "#E7E7E7",
  },
  viewAllTxt: {
    fontFamily: "Gilroy-Medium",
    fontSize: 13,
    color: "#909090",
  },
  btnText: { fontFamily: "Gilroy-SemiBold", fontSize: 14 },
  searchInputVue: {
    height: 50,
    backgroundColor: "#FAFAFA",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 10,
    width: "100%",
    flex: 1,
    borderRadius: 4,
  },
  searchFilterVue: {
    width: windowWidth,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 15,
    marginBottom: 15,
  },
  filterIconBg: {
    backgroundColor: "#FAFAFA",
    height: 50,
    width: 54,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  tabSection: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 15,
    marginBottom: 15,
  },
  tabs: { width: "50%", borderBottomWidth: 2, alignItems: "center" },
  tabTxt: { fontFamily: "Gilroy-SemiBold", fontSize: 14, marginVertical: 11 },
});
