import React from "react";
import { StyleSheet, View, Dimensions, ActivityIndicator } from "react-native";

function HelpScreens() {
  const source = {
    uri: "https://drive.google.com/u/1/uc?id=14wpRD6k3avlwuLQETXtcREFRkD354Ns-&export=download",
    cache: true,
  };
  return <View style={styles.container}></View>;
}

export default HelpScreens;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
  },
  pdf: {
    flex: 1,
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height,
  },
});
