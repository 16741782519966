import React, {useEffect, useState} from 'react';
import {
  ScrollView,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
} from 'react-native';

import uncheck from '../../assets/images/uncheck.png';
import check from '../../assets/images/check.png';

function TrackOrder(props) {
  const finalStatus = ['Closed', 'Rejected'];

  const moment = require('moment');

  function convertTimeDate(datetime) {
    if (datetime !== undefined) {
      let activityDateTime = moment(datetime).format(`MMM DD hh:mm`);
      return activityDateTime;
    } else {
      return '';
    }
  }

  const sampleData = props.route.params.tracking;

  return (
    <SafeAreaView style={styles.background}>
      <ScrollView>
        <View style={styles.parentDiv}>
          {sampleData.map((status, index) => {
            const dateNtime = moment(status.date_time).format(`MMM DD h:mm a`);

            const orderStatus = status.status;

            return (
              <View style={styles.trackData}>
                <View style={styles.date_time}>
                  <Text style={styles.statusDate}>{dateNtime}</Text>
                </View>
                <View style={styles.checks}>
                  <Image style={styles.chkImg} source={check} />
                  {!finalStatus.includes(orderStatus) && (
                    <View style={[styles.trackLine, {height: 65}]} />
                  )}
                </View>
                <View style={styles.status}>
                  <Text style={styles.statusTxt}>
                    {orderStatus === 'PO Created'
                      ? 'Material Out For Delivery'
                      : orderStatus}
                  </Text>
                </View>
              </View>
            );
          })}

          {!finalStatus.includes(sampleData[sampleData.length - 1].status) && (
            <View style={[styles.trackData, {paddingBottom: 25}]}>
              <View style={styles.date_time} />
              <View style={styles.checks}>
                <Image style={styles.chkImg} source={uncheck} />
              </View>
              <View style={styles.status} />
            </View>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  background: {
    backgroundColor: '#F8F8F8',
    flex: 1,
  },
  parentDiv: {
    backgroundColor: '#FFFFFF',
    paddingVertical: 35,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: 8,
    margin: 20,
    marginBottom: 35,
  },
  trackData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 45,
  },
  date: {
    width: windowWidth / 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  checks: {
    width: 50,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  status: {
    width: windowWidth / 3,
  },
  statusDate: {
    fontSize: 13,
    fontFamily: 'Gilroy-Medium',
    color: '#969696',
    lineHeight: 18,
  },
  statusTxt: {fontSize: 15, fontFamily: 'Gilroy-Medium'},
  chkImg: {width: 24, height: 24},
  trackLine: {
    position: 'absolute',
    borderLeftWidth: 2,
    left: '22%',
    top: 24,
    borderStyle: 'solid',
    borderLeftColor: '#FCC216',
  },
  date_time: {
    width: 60,
    marginRight: 5,
  },
});

export default TrackOrder;
