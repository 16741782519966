import AsyncStorage from '@react-native-async-storage/async-storage';
import React, {useEffect} from 'react';
import {View, Image, StyleSheet, Dimensions} from 'react-native';
import axiosClientInterceptors from './Auth';
import splashScreen from '../../assets/SplashScreen/launch_screen.png';
import Config from './Config';

function SplashScreen(props) {
  useEffect(() => {
    getDetails();
  }, []);

  async function getDetails() {
    const seId = await AsyncStorage.getItem('_id');
    const token = await AsyncStorage.getItem('authToken');
    if (seId === null || token === null) {
      props.navigation.navigate('Login');
    } else {
      verifyToken(seId, token);
    }
  }

  function goHome(res) {
    props.navigation.reset({
      index: 0,
      routes: [{name: 'Home'}],
    });
    Config.username = res.data.name;
    Config.SiteEnggId = res.data._id;
  }

  function verifyToken(seId, token) {
    axiosClientInterceptors
      .post(`/verify-token?_id=${seId}`, {token: token})
      .then(res =>
        res.data.status
          ? goHome(res)
          : props.navigation.reset({
              index: 0,
              routes: [{name: 'Login'}],
            }),
      );
  }

  return (
    <View style={styles.parentVue}>
      <Image
        source={splashScreen}
        style={[styles.splashImg, styles.parentVue]}
      />
    </View>
  );
}

export default SplashScreen;

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  parentVue: {height: windowHeight, width: windowWidth},
  splashImg: {resizeMode: 'cover'},
});
