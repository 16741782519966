import AsyncStorage from "@react-native-async-storage/async-storage";
import LinearGradient from "react-native-web-linear-gradient";
import React, { useEffect, useState } from "react";
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  ActivityIndicator,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  TextInput,
  Modal,
} from "react-native";
import axiosClientInterceptors from "../BasicComponents/Auth";
import Config from "../BasicComponents/Config";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const Login = ({ navigation }) => {
  const [emailLabel, setEmailLabel] = useState(styles.lables);
  const [pswdLabel, setPswdLabel] = useState(styles.lables);
  const [showLoader, setShowLoader] = useState(false);
  const [emailInputBorder, setEmailInputBorder] = useState("#969696");
  const [emailLabelSize, setEmailLabelSize] = useState(15);
  const [pswdInputBorder, setPswdInputBorder] = useState("#969696");
  const [pswdLabelSize, setPswdLabelSize] = useState(15);
  const [emailError, setEmailError] = useState(1);
  const [email, setEmail] = useState("");
  const [pswd, setPswd] = useState("");
  const [hidePass, setHidePass] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalText, setModalText] = useState("");

  useEffect(() => {
    AsyncStorage.removeItem("_id");
    AsyncStorage.removeItem("authToken");
    AsyncStorage.removeItem("Name");
  }, []);

  function emailInput() {
    setEmailLabel(styles.lablesOnFocus);
    setEmailInputBorder("#F9C423");
    setPswdInputBorder("black");
    setEmailLabelSize(12);
  }
  function pswdInput() {
    setPswdLabel(styles.lablesOnFocus);
    setPswdInputBorder("#F9C423");
    setEmailInputBorder("black");
    setPswdLabelSize(12);
  }

  function validateEmail() {
    var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (regex.test(email)) {
      setEmailError(true);
      submitForm();
    } else {
      setEmailError(false);
    }
  }

  async function submitForm() {
    if (emailError !== "" && email !== "" && pswd !== "") {
      setShowLoader(true);
      axiosClientInterceptors
        .post(`/signin`, {
          username: email,
          password: pswd,
        })
        .then(function (response) {
          if (response.data.status === "success") {
            AsyncStorage.setItem("Name", response.data.Name);
            AsyncStorage.setItem("_id", response.data._id);
            AsyncStorage.setItem("authToken", response.data.token);
            Config.username = response.data.Name;
            Config.authToken = response.data.token;
            Config.SiteEnggId = response.data._id;
            navigation.reset({
              index: 0,
              routes: [{ name: "Home" }],
            });
          } else {
            setModalText("Invalid Email-ID or Password");
            setModalVisible(!modalVisible);
            setShowLoader(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  return (
    <SafeAreaView>
      <ScrollView keyboardShouldPersistTaps={"always"}>
        <View style={styles.screen}>
          <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}
          >
            <View style={styles.centeredView}>
              <View style={styles.modalView}>
                <Image
                  style={styles.errorInfoImg}
                  source={require("../../assets/images/errorInfo.png")}
                />
                <Text style={styles.modalText}>{modalText}</Text>
                {/* <Button
                  style={[styles.modalButtonClose,{backgroundColor: 'red'}]}
                  onPress={() => setModalVisible(!modalVisible)}
                  title="Close"
                /> */}
                <TouchableOpacity
                  onPress={() => setModalVisible(!modalVisible)}
                >
                  <LinearGradient
                    colors={["#FF3535", "#AF1818"]}
                    start={{ x: 0, y: 1 }}
                    end={{ x: 1, y: 0 }}
                    style={styles.modalButton}
                  >
                    <Text style={[styles.btnText, { color: "white" }]}>
                      Try Again
                    </Text>
                  </LinearGradient>
                </TouchableOpacity>
              </View>
            </View>
          </Modal>
          <Image
            style={styles.cendrolImg}
            source={require("../../assets/images/bgimg.jpg")}
          />
          <View style={styles.loginCard}>
            <View style={styles.infoText}>
              <Text style={styles.headText}>Sign in to continue</Text>
              <Text style={styles.subText}>
                Order materials & track your orders.
              </Text>
            </View>
            <View style={[styles.textInput, { borderColor: emailInputBorder }]}>
              <Text
                style={[
                  emailLabel,
                  styles.inTextBox,
                  { fontSize: emailLabelSize },
                ]}
              >
                Email
              </Text>
              <TextInput
                color="black"
                onFocus={() => emailInput()}
                style={[styles.input, { fontFamily: "Gilroy-SemiBold" }]}
                onChangeText={(text) => setEmail(text.toLowerCase())}
              />
            </View>
            {emailError ? null : (
              <Text style={styles.invalidEmailTxt}>Enter valid Email ID</Text>
            )}

            <View
              style={[
                styles.textInput,
                { borderColor: pswdInputBorder, marginBottom: 15 },
              ]}
            >
              <Text
                style={[
                  pswdLabel,
                  styles.inTextBox,
                  { fontSize: pswdLabelSize },
                ]}
              >
                Password
              </Text>
              <View
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TextInput
                  color="black"
                  onFocus={() => pswdInput()}
                  style={[
                    styles.input,
                    { width: 250, fontFamily: "Gilroy-SemiBold" },
                  ]}
                  onChange={(e) => setPswd(e.nativeEvent.text)}
                  secureTextEntry={hidePass ? true : false}
                ></TextInput>
              </View>
              <Text onPress={() => setHidePass(!hidePass)}>Show Password</Text>
            </View>

            {/* <View style={styles.chkbx}>
              <Checkbox
                status={checked ? 'checked' : 'unchecked'}
                onPress={() => {
                  setChecked(!checked);
                }}
                color="#F9C423"
              />
              <Text style={styles.rememberMe}>Remember me</Text>
            </View> */}

            <TouchableOpacity
              onPress={() => validateEmail()}
              style={{ marginTop: 8, width: "95%" }}
            >
              <LinearGradient
                colors={["#FFE185", "#F9C423", "#F6BA00"]}
                start={{ x: 0, y: 1 }}
                end={{ x: 1, y: 0 }}
                title="Login"
                style={styles.loginButton}
              >
                <Text
                  style={{
                    fontSize: 15,
                    fontFamily: "Gilroy-SemiBold",
                    color: "black",
                  }}
                >
                  Login
                </Text>
              </LinearGradient>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      {showLoader && (
        <ActivityIndicator size="large" color="#ffcd30" style={styles.loader} />
      )}
    </SafeAreaView>
  );
};

export default Login;

const styles = StyleSheet.create({
  screen: {
    minHeight: windowHeight,
    width: windowWidth,
    backgroundColor: "#FFFFFF",
    alignItems: "center",
    paddingBottom: 50,
  },
  cendrolImg: {
    width: windowWidth,
    height: 215,
    resizeMode: "cover",
  },
  headText: {
    color: "black",
    fontSize: 30,
    paddingBottom: 10,
    fontFamily: "PlayfairDisplay-Bold",
    // fontWeight: 'ExtraBold',
  },
  subText: {
    color: "#8a8a8a",
    fontSize: 14,
    fontFamily: "Gilroy-Medium",
  },
  loginCard: {
    width: "100%",
    flex: 1,
    alignItems: "center",
    paddingTop: 50,
    borderRadius: 15,
    backgroundColor: "white",
    marginTop: -25,
    paddingHorizontal: 25,
  },
  infoText: {
    alignItems: "center",
    marginBottom: 35,
  },
  textInput: {
    marginTop: 20,
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderWidth: 0.6,
    borderRadius: 3,
    borderColor: "#969696",
    height: 60,
    width: "95%",
    alignContent: "flex-start",
  },
  lables: {
    color: "#8a8a8a",
    fontSize: 15,
    marginTop: 10,
  },
  lablesOnFocus: {
    color: "#8a8a8a",
    fontSize: 15,
    paddingBottom: 10,
  },
  rememberMe: {
    color: "black",
    fontSize: 15,
  },
  chkbx: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 15,
    marginBottom: 15,
    width: windowWidth / 1.2,
    height: 30,
  },
  loginButton: {
    backgroundColor: "#F9C423",
    width: "100%",
    height: 55,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 3,
    marginTop: 8,
  },
  input: {
    marginTop: -18,
    height: 45,
    fontSize: 15,
    marginLeft: -2.5,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: windowWidth / 1.2,
  },
  modalText: {
    marginBottom: 25,
    textAlign: "left",
    color: "black",
    fontFamily: "Gilroy-Bold",
    fontSize: 15,
  },
  errorInfoImg: {
    // objectFit: 'contain',
    width: 60,
    height: 60,
    marginBottom: 15,
  },
  modalButtonClose: {
    marginTop: 15,
    height: 45,
    width: windowWidth / 2,
    borderRadius: 5,
  },
  inTextBox: {
    fontFamily: "Gilroy-Medium",
  },
  modalButton: {
    // backgroundColor: '#ff4040',
    height: 45,
    width: windowWidth / 3,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
  },
  btnText: {
    fontSize: 15,
    fontFamily: "Gilroy-SemiBold",
  },
  loader: {
    position: "absolute",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 100,
    bottom: windowHeight / 3,
  },
  invalidEmailTxt: {
    color: "red",
    alignSelf: "flex-start",
    marginTop: 2,
    marginLeft: 15,
    fontFamily: "Gilroy-Medium",
    fontSize: 11,
  },
});
