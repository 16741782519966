import React, { useEffect, useState, useRef } from "react";
import LinearGradient from "react-native-web-linear-gradient";
import AsyncStorage from "@react-native-async-storage/async-storage";
import DaysLeft from "../BasicComponents/DaysLeft";
import axiosClientInterceptors from "../BasicComponents/Auth";

import {
  SafeAreaView,
  ScrollView,
  RefreshControl,
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  Modal,
  BackHandler,
  ActivityIndicator,
  Linking,
  TextInput,
} from "react-native";

import Config from "../BasicComponents/Config";

const windowWidth = Dimensions.get("window").width;
const windowHeight = Dimensions.get("window").height;

const SiteEngHome = ({ navigation }) => {
  const [allProjects, setAllProjects] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [search, setSearch] = useState("");
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [projHealthFilters, setProjHealthFilters] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    getApiData();
    setShowLoader(true);
  }, []);

  async function getApiData() {
    try {
      console.log(`/SEprojects?site_engineer=${Config.SiteEnggId}`);
      axiosClientInterceptors({
        method: "post",
        url: `/SEprojects?site_engineer=${Config.SiteEnggId}`,
      }).then((response) => {
        setAllProjects(response.data.projects);
        setShowLoader(false);
        setRefreshing(false);
      });
    } catch (e) {
      console.log(e);
    }
  }

  async function applyFilter(reset) {
    const projHealth = reset ? [] : projHealthFilters;
    setShowLoader(true);
    axiosClientInterceptors
      .post(`/SEprojects?site_engineer=${Config.SiteEnggId}`, {
        project_health: projHealth,
      })
      .then((response) => {
        setAllProjects(response.data.projects);
        setShowLoader(false);
      });
    setFilterModalVisible(false);
  }

  const [allBg, setAllBg] = useState("#F6F6F6");
  const [allStatBg, setAllStatBg] = useState("#F6F6F6");

  const reqStatList = [
    "Super Critical",
    "Critical",
    "Sub-Critical",
    "Progressing",
    "Handover",
    "New Project",
  ];

  const [refreshing, setRefreshing] = React.useState(false);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    getApiData();
  }, []);

  const scrollRef = useRef();

  function projects(projectInfo) {
    let color;
    let textColor;
    switch (projectInfo.project_health) {
      case "Super Critical":
        color = "#FFF0F0";
        textColor = "#FE3E3E";
        break;
      case "Critical":
        color = "#FFFAEC";
        textColor = "#FCC314";
        break;
      case "New Project":
        color = "#FBF3FF";
        textColor = "#DF3BFA";
        break;
      case "Sub-Critical":
        color = "#FFFAEC";
        textColor = "#FCC314";
        break;
      case "Progressing":
        color = "#F1FDFF";
        textColor = "#3EE7FE";
        break;
      case "Handover":
        color = "#EFFCF2";
        textColor = "#3ADD5E";
        break;
      // case 'New Project':
      //   color = '#FBE4FF';
      //   textColor = '#E33EFE';
      //   break;
      default:
        color = "grey";
        textColor = "white";
    }

    var progressPerc = "0%";
    if (projectInfo.project_progress !== undefined) {
      progressPerc = projectInfo.project_progress + "%";
    } else {
      progressPerc = 0 + "%";
    }

    return (
      <TouchableOpacity
        title="orderMaterials"
        onPress={() =>
          navigation.navigate("ProjectDetails", {
            projectId: projectInfo._id,
          })
        }
      >
        <View style={styles.cards} key={projectInfo._id}>
          <View style={styles.cardInner}>
            <Text style={[styles.infoText, { maxWidth: windowWidth / 3 }]}>
              {projectInfo.project_name}
            </Text>
            <Text style={styles.cardMidTxt}>
              Days Left: <DaysLeft endDate={projectInfo.construction_enddate} />
            </Text>
          </View>
          <View style={styles.percBarOuter}>
            <LinearGradient
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 0 }}
              colors={["#FFE185", "#F6BA00"]}
              style={{
                height: 12,
                width: progressPerc,
                borderRadius: 10,
              }}
            ></LinearGradient>
          </View>
          <View style={styles.underProgBar}>
            <Text style={[styles.cardMidTxt, { color: "black" }]}>
              Progress ({progressPerc})
            </Text>
            <Text style={styles.cardSbTxt}>{projectInfo.project_stage}</Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              width: windowWidth / 1.2,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <View style={[styles.health, { backgroundColor: color }]}>
              <Text
                style={[
                  styles.cardSbTxt,
                  {
                    color: textColor,
                  },
                ]}
              >
                {projectInfo.project_health}
              </Text>
            </View>
            <Image
              style={styles.rightBtn}
              source={require("../../assets/images/RightBtn.png")}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  }

  function logout() {
    AsyncStorage.clear();
    navigation.reset({
      index: 0,
      routes: [{ name: "Login" }],
    });
  }

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Image
              style={styles.errorInfoImg}
              source={require("../../assets/images/errorInfo.png")}
            />
            <Text style={[styles.infoText, { marginBottom: 5 }]}>
              Oh no! You're leaving...
            </Text>
            <Text style={[styles.infoText, { marginBottom: 0 }]}>
              Are you sure?
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <TouchableOpacity
                style={[styles.modalButton, { backgroundColor: "#FD3434" }]}
                onPress={() => {
                  logout();
                }}
              >
                <Text style={[styles.btnText, { color: "white" }]}>Logout</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, { backgroundColor: "#f4f4f4" }]}
                onPress={() => setModalVisible(!modalVisible)}
              >
                <Text style={styles.btnText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={filterModalVisible}
        onRequestClose={() => {
          setFilterModalVisible(false);
        }}
      >
        <ScrollView
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
          showsVerticalScrollIndicator={false}
        >
          <View style={styles.centeredFilterView}>
            <View style={styles.modalFilterView}>
              <View style={styles.filterHeader}>
                <View style={{ flexDirection: "row" }}>
                  <Text
                    style={[
                      styles.infoText,
                      { fontFamily: "Gilroy-Bold", fontSize: 18 },
                    ]}
                  >
                    Apply Filter
                  </Text>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    setFilterModalVisible(false);
                  }}
                >
                  <Image
                    style={{ height: 15, width: 15, bottom: 8, marginRight: 5 }}
                    source={require("../../assets/images/closeBtn.png")}
                  />
                </TouchableOpacity>
              </View>
              <Text
                style={[
                  styles.infoText,
                  { flexDirection: "row", width: windowWidth / 1.1 },
                ]}
              >
                Project Health
              </Text>
              <View style={styles.filterElementsView}>
                <TouchableOpacity
                  style={[
                    styles.filterElements,
                    { backgroundColor: allStatBg },
                  ]}
                  onPress={() => (
                    setAllStatBg("#FCC216"), setProjHealthFilters([])
                  )}
                >
                  <Text style={[styles.filterText, { fontSize: 14 }]}>All</Text>
                </TouchableOpacity>
                {reqStatList.map((reqStat) => {
                  let bg2;
                  if (projHealthFilters.includes(reqStat)) {
                    bg2 = "#FCC216";
                  } else {
                    bg2 = "#F6F6F6";
                  }
                  const stat = reqStat;
                  return (
                    <TouchableOpacity
                      style={[styles.filterElements, { backgroundColor: bg2 }]}
                      onPress={() => {
                        var array = [];
                        if (projHealthFilters.includes(stat)) {
                          var arr = projHealthFilters.filter(
                            (item) => item !== stat
                          );
                          bg2 = "#FCC216";
                          setProjHealthFilters(arr);
                        } else {
                          setProjHealthFilters([...projHealthFilters, reqStat]);
                          setAllStatBg("#F6F6F6");
                        }
                      }}
                    >
                      <Text style={[styles.filterText, { fontSize: 14 }]}>
                        {reqStat}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
              </View>
            </View>
          </View>
        </ScrollView>
        <View style={styles.filterBottom}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              applyFilter(true);
              setProjHealthFilters([]);
              setAllBg("#F6F6F6");
              setAllStatBg("#F6F6F6");
            }}
          >
            <Text style={styles.btnText}>Clear Filter</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => applyFilter()}>
            <LinearGradient
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 0 }}
              colors={["#FFE185", "#F6BA00"]}
              style={[
                {
                  height: 44,
                  width: 137,
                  borderRadius: 2,
                  justifyContent: "center",
                  alignItems: "center",
                },
              ]}
            >
              <Text style={styles.btnText}>Apply Filter</Text>
            </LinearGradient>
          </TouchableOpacity>
        </View>
      </Modal>

      <ScrollView
        ref={scrollRef}
        style={styles.parentVue}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <View style={styles.homeHeader}>
          <Text style={styles.header}>Hello, {Config.username} </Text>
          <TouchableOpacity
            onPress={() => {
              setModalVisible(true);
            }}
          >
            <View style={styles.logoutCircle}></View>
          </TouchableOpacity>
        </View>

        <View style={styles.supportVue}>
          <TouchableOpacity
            activeOpacity={1}
            style={styles.supportContainers}
            onPress={() => {
              navigation.navigate("HelpScreens");
            }}
          >
            <View style={styles.suppIconBg}></View>
            <Text style={styles.supportHeader}>User Manual Doc.</Text>
            <Text style={styles.supportTxtBtn}>View Document</Text>
          </TouchableOpacity>
          <TouchableOpacity
            activeOpacity={1}
            style={styles.supportContainers}
            onPress={() => {
              Linking.openURL(`tel:${9148978477}`);
            }}
          >
            <View style={styles.suppIconBg}></View>
            <Text style={styles.supportHeader}>Customer Care</Text>
            <Text style={styles.supportTxtBtn}>Contact Now</Text>
          </TouchableOpacity>
        </View>

        <Text
          style={styles.allProjsTxt}
          onPress={() => navigation.navigate("HelpScreens")}
        >
          All Projects
        </Text>

        <View style={styles.searchNfilter}>
          <View style={styles.searchVue}>
            <TextInput
              placeholder="Search by Project Name or ID"
              onChange={(e) => (
                setSearch(e.nativeEvent.text.toLowerCase()),
                scrollRef.current?.scrollTo({
                  y: 230,
                  animated: false,
                })
              )}
              style={{ width: windowWidth / 1.7, fontFamily: "Gilroy-Medium" }}
            />
          </View>
          <TouchableOpacity
            onPress={() => {
              setFilterModalVisible(true);
            }}
          >
            <View style={styles.filterIconBg}>
              <Image
                style={{ height: 25, width: 25 }}
                source={require("../../assets/images/filter.png")}
              />
            </View>
          </TouchableOpacity>
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          {allProjects.map((projectInfo) => {
            if (search !== "") {
              if (
                projectInfo.project_name.toLowerCase().includes(search) ||
                projectInfo.project_id.toLowerCase().includes(search)
              ) {
                return projects(projectInfo);
              }
            } else {
              return projects(projectInfo);
            }
          })}
          {allProjects.length === 0 && (
            <Image
              style={styles.noData}
              source={require("../../assets/images/noProject.png")}
            />
          )}
        </View>
        <Text style={styles.versionNo}>
          {Config.APP_VERSION} {Config.REACT_APP_ENVIRONMENT}
        </Text>
      </ScrollView>
      {showLoader && (
        <ActivityIndicator size="large" color="#ffcd30" style={styles.loader} />
      )}
    </SafeAreaView>
  );
};

export default SiteEngHome;

const styles = StyleSheet.create({
  homeHeader: {
    marginTop: 25,
    marginBottom: 5,
    marginHorizontal: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  parentVue: {
    backgroundColor: "white",
    flex: 1,
  },
  burgerMenu: {
    width: 28,
    height: 22.5,
  },
  LogOut: {
    width: 26,
    height: 26,
  },

  header: {
    fontSize: 20,
    fontFamily: "Gilroy-Bold",
    color: "black",
    alignItems: "center",
  },
  notifCircle: {
    borderRadius: 25,
    backgroundColor: "#F8F8F8",
    padding: 5,
  },
  logoutCircle: {
    borderRadius: 100,
    backgroundColor: "#F8F8F8",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  notifIcon: {
    color: "black",
    fontSize: 30,
  },
  searchIcon: {
    color: "black",
    fontSize: 30,
    margin: 10,
  },
  taskImg: {
    height: 43,
    width: 50,
  },
  xImg: {
    height: 40,
    width: 40,
  },
  header2: {
    marginTop: 18,
    margin: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rightBtn: {
    height: 20,
    width: 20,
    padding: 3,
  },
  lableText: {
    color: "#a1a1a1",
    fontSize: 13,
  },
  infoText: {
    color: "black",
    fontFamily: "Gilroy-Bold",
    fontSize: 15,
    marginBottom: 20,
  },
  filterText: {
    color: "black",
    fontFamily: "Gilroy-Medium",
    fontSize: 16,
  },
  cards: {
    alignItems: "center",
    margin: 18,
    marginBottom: 5,
    justifyContent: "center",
    borderRadius: 4,
    paddingVertical: 18,
    backgroundColor: "#FAFAFA",
  },
  health: {
    paddingVertical: 8,
    paddingHorizontal: 14,
    borderRadius: 3,
    justifyContent: "center",
    maxWidth: windowWidth / 2,
  },
  modalFilterView: {
    backgroundColor: "white",
    // borderRadius: 20,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 25,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: windowWidth,
  },
  errorInfoImg: {
    // objectFit: 'contain',
    width: 60,
    height: 60,
    marginBottom: 25,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 2,
    padding: 25,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: windowWidth / 1.1,
  },
  modalText: {
    marginBottom: 25,
    textAlign: "center",
    color: "black",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
  },
  centeredFilterView: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    // paddingTop: windowHeight / 2,
    marginTop: windowHeight / 2.2,
  },
  modalButton: {
    // backgroundColor: '#ff4040',
    marginTop: 25,
    height: 45,
    width: "48%",
    borderRadius: 2,
    alignItems: "center",
    justifyContent: "center",
  },
  btnText: {
    fontSize: 15,
    fontFamily: "Gilroy-SemiBold",
  },
  filterHeader: {
    width: windowWidth / 1.1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 25,
    alignItems: "center",
    borderBottomWidth: 1,
    borderColor: "#DEDEDE",
  },
  filterElements: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: "#F6F6F6",
    justifyContent: "center",
    borderRadius: 3,
    marginVertical: 10,
    marginHorizontal: 5,
  },
  filterElementsView: {
    width: windowWidth / 1.1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    paddingBottom: 10,
    marginBottom: 15,
  },
  button: {
    backgroundColor: "#ffffff",
    borderRadius: 3,
    borderWidth: 1,
    borderColor: "#EAEAEA",
    width: 137,
    height: 44,
    justifyContent: "center",
    alignItems: "center",
  },
  filterBottom: {
    width: windowWidth,
    flexDirection: "row",
    justifyContent: "space-around",
    paddingVertical: 22,
    backgroundColor: "white",
    elevation: 10,
  },
  noData: {
    width: 316,
    height: 253,
    alignSelf: "center",
    justifyContent: "center",
    marginTop: 50,
    resizeMode: "contain",
    marginBottom: 15,
  },
  loader: {
    position: "absolute",
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 100,
    bottom: windowHeight / 3,
  },
  cardInner: {
    width: windowWidth / 1.2,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardMidTxt: {
    color: "#FE3E3E",
    fontFamily: "Gilroy-Medium",
    fontSize: 14,
  },
  cardSbTxt: {
    fontFamily: "Gilroy-SemiBold",
    fontSize: 14,
  },
  percBarOuter: {
    width: windowWidth / 1.2,
    backgroundColor: "#F4F4F4",
    height: 12,
    borderRadius: 10,
    marginBottom: 10,
  },
  underProgBar: {
    flexDirection: "row",
    width: windowWidth / 1.2,
    justifyContent: "space-between",
    marginBottom: 20,
  },
  filterIconBg: {
    backgroundColor: "#FAFAFA",
    height: 50,
    width: 54,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
  },
  searchVue: {
    height: 50,
    backgroundColor: "#FAFAFA",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: 10,
    maxWidth: windowWidth,
    flex: 1,
    borderRadius: 4,
  },
  searchNfilter: {
    width: windowWidth,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 16,
  },
  supportVue: {
    display: "flex",
    marginHorizontal: 15,
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 25,
  },
  supportContainers: {
    padding: 15,
    backgroundColor: "#FFFAE9",
    width: "48%",
    borderRadius: 2,
  },
  suppIconBg: {
    borderRadius: 100,
    backgroundColor: "#FCC200",
    height: 42,
    width: 42,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 5,
  },
  supportHeader: {
    fontFamily: "Gilroy-SemiBold",
    fontSize: 16,
    marginVertical: 5,
  },
  supportTxtBtn: {
    fontFamily: "Gilroy-Medium",
    fontSize: 12,
    color: "#909090",
    textDecorationLine: "underline",
  },
  allProjsTxt: {
    fontSize: 20,
    fontFamily: "Gilroy-Bold",
    color: "black",
    marginHorizontal: 15,
    marginVertical: 15,
  },
  versionNo: {
    fontFamily: "Gilroy-Medium",
    width: "100%",
    textAlign: "center",
    marginTop: 25,
    color: "#dbdbdb",
  },
});
