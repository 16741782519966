import React, { useState, useEffect, useCallback } from "react";
import {
  SafeAreaView,
  ScrollView,
  ActivityIndicator,
  StyleSheet,
  Text,
  Alert,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  TextInput,
  Modal,
  ToastAndroid,
  FlatList,
} from "react-native";
import { Picker } from "@react-native-picker/picker";
import Config from "../BasicComponents/Config";
import LinearGradient from "react-native-web-linear-gradient";

function MaterialSelection(props) {
  const [ticked, setTicked] = useState(false);
  const [uom, setUom] = useState("");
  const [qty, setQty] = useState(1);
  const [refreshVue, setRefreshVue] = useState(1);

  useEffect(() => {
    !props.ticked
      ? props.updateData({
          material_name: props.materialName,
          ticked: ticked,
          uom: uom,
          quantity: qty,
          uomList: props.uom,
        })
      : uom !== "" &&
        props.updateData({
          material_name: props.materialName,
          ticked: ticked,
          uom: uom,
          quantity: qty,
          uomList: props.uom,
        });
  }, [ticked, uom, qty]);

  useEffect(() => {
    if (
      Config.selectedMaterials.filter(
        (val) => val.material_name === props.materialName
      ).length > 0
    ) {
      const index = Config.selectedMaterials.findIndex(
        (obj) => obj.material_name === props.materialName
      );
      const prevData = Config.selectedMaterials[index];
      setTicked(prevData.ticked);
      setQty(prevData.quantity);
      setUom(prevData.uom);
    }
    setRefreshVue((prev) => prev + 1);
  }, []);

  const Dropdown = () => (
    <Picker
      style={{
        alignItems: "center",
        flex: 1,
        padding: 2,
        backgroundColor: "white",
      }}
      placeholder="UOM"
      selectedValue={uom}
      dropdownIconColor="#FCC314"
      dropdownIconRippleColor="#FCC314"
      mode={"dropdown"}
      onValueChange={(itemValue) => {
        itemValue !== "0" && setUom(itemValue);
      }}
    >
      <Picker.Item
        label="Select UOM"
        value="0"
        style={styles.btnText}
        color={"#969696"}
      />

      {props.uom?.map((uom) => (
        <Picker.Item label={uom} value={uom} style={styles.btnText} />
      ))}
    </Picker>
  );

  const NumberInput = useCallback(() => {
    let value = qty;

    const handleQtyChange = (val) => {
      val > 0 ? (value = val) : (value = 1);
      refreshSet(false);
    };

    function incDec(action) {
      const qtyVal = +value;
      action === "plus"
        ? (value = qtyVal + 1)
        : action === "minus" && +qtyVal > 1 && (value = qtyVal - 1);
      refreshSet();
    }

    function refreshSet(refresh = true) {
      setQty(value);
      refresh && setRefreshVue((prev) => prev + 1);
    }

    return (
      <View style={styles.numberCounter}>
        <TouchableOpacity
          style={[
            styles.plusMinusBtn,
            { borderBottomLeftRadius: 3, borderTopLeftRadius: 3 },
          ]}
          onPress={() => incDec("minus")}
        ></TouchableOpacity>
        <TextInput
          style={styles.numberInput}
          defaultValue={String(value)}
          onChangeText={(val) => handleQtyChange(val)}
          onBlur={() => setRefreshVue((prev) => prev + 1)}
          keyboardType="numeric"
        />
        <TouchableOpacity
          style={[
            styles.plusMinusBtn,
            { borderBottomRightRadius: 3, borderTopRightRadius: 3 },
          ]}
          onPress={() => {
            incDec("plus");
          }}
        ></TouchableOpacity>
      </View>
    );
  }, [refreshVue]);

  function deleteFromStorage() {
    props.updateData({
      material_name: props.materialName,
      ticked: false,
      uom: uom,
      quantity: qty,
      uomList: props.uom,
    });
  }

  return (
    <View style={{ marginHorizontal: 15 }}>
      {!props.ticked ? (
        <TouchableOpacity
          style={styles.materialNameRows}
          onPress={() => setTicked(!ticked)}
        >
          <View
            style={[
              styles.checkBox,
              { backgroundColor: ticked ? "#FCC200" : "white" },
            ]}
          ></View>
          <Text style={styles.materialName}>{props.materialName}</Text>
        </TouchableOpacity>
      ) : (
        !ticked && setTicked(true)
      )}
      {ticked && (
        <View style={styles.specsVue}>
          <View style={styles.specInputDivs}>
            <Text style={styles.specSelectLabel}>Unit of Measure</Text>
            <Dropdown />
          </View>
          <ScrollView
            style={styles.specInputDivs}
            keyboardDismissMode="interactive"
          >
            <Text style={styles.specSelectLabel}>Quantity</Text>
            <NumberInput />
          </ScrollView>
        </View>
      )}
      {props.ticked && (
        <View style={styles.editPopBtnsVue}>
          <TouchableOpacity
            style={styles.popupEditBtn}
            onPress={() => deleteFromStorage()}
          >
            <Text style={styles.btnText}> Delete</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => props.saveToStorage()}
            style={styles.popupEditBtn}
          >
            <LinearGradient
              colors={["#FFE185", "#F6BA00"]}
              start={{ x: 0, y: 1 }}
              end={{ x: 1, y: 0 }}
              style={[styles.popupEditBtn, { width: "100%", marginBottom: 0 }]}
            >
              <Text style={styles.btnText}>Save Changes</Text>
            </LinearGradient>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}

export default MaterialSelection;

const styles = StyleSheet.create({
  materialName: {
    fontFamily: "Gilroy-Medium",
    fontSize: 13,
  },
  materialNameRows: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: 8,
    alignItems: "center",
    marginBottom: 5,
  },
  checkBox: {
    borderWidth: 1,
    borderColor: "#FCC200",
    height: 24,
    width: 24,
    borderRadius: 2,
    marginRight: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  gradientBtn: {
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2,
    marginVertical: 15,
  },
  btnText: {
    fontFamily: "Gilroy-Medium",
    fontSize: 13,
  },
  tickMark: {
    flex: 1,
    width: "100%",
    resizeMode: "cover",
  },
  specsVue: {
    display: "flex",
    flexDirection: "row",
    paddingVertical: 15,
    paddingHorizontal: 5,
    backgroundColor: "#F9F9F9",
    marginBottom: 15,
  },
  specInputDivs: {
    flex: 1,
    paddingHorizontal: 10,
  },
  specSelectLabel: {
    fontFamily: "Gilroy-Medium",
    fontSize: 12,
    marginBottom: 8,
  },
  materialName: {
    fontFamily: "Gilroy-Medium",
    fontSize: 13,
  },
  numberCounter: {
    display: "flex",
    flexDirection: "row",
  },
  numberInput: {
    flex: 1,
    height: "100%",
    backgroundColor: "white",
    textAlign: "center",
    fontFamily: "Gilroy-SemiBold",
    fontSize: 15,
  },
  plusMinusBtn: {
    backgroundColor: "#FFF3CD",
    justifyContent: "center",
    paddingHorizontal: 12,
  },
  editPopBtnsVue: {
    display: "flex",
    flexDirection: "row",
    marginVertical: 5,
    justifyContent: "space-between",
  },
  popupEditBtn: {
    width: "47%",
    height: 42,
    backgroundColor: "#F4F4F4",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
    borderRadius: 2,
  },
});
