import React, {useCallback, useEffect, useState} from 'react';
import AddMaterial from '../AddMaterial/AddMaterial';
import LinearGradient from 'react-native-web-linear-gradient';
import {Picker} from '@react-native-picker/picker';
import DateTimePickerModal from 'react-native-modal-datetime-picker';
import axiosClientInterceptors from '../BasicComponents/Auth';
import {
  SafeAreaView,
  ScrollView,
  ActivityIndicator,
  StyleSheet,
  Text,
  Alert,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  TextInput,
  Modal,
  ToastAndroid,
  FlatList,
} from 'react-native';
import Config from '../BasicComponents/Config';
import {useFocusEffect} from '@react-navigation/native';
import MaterialSelection from './MaterialSelection';

function OrderMaterials(props, {navigation}) {
  const [materialCategory, setMaterialCategory] = useState('');
  const [matCatList, setMatCatlist] = useState([]);
  const [dueDate, setDueDate] = useState('');
  const [materials, setMaterials] = useState([]);
  const [tempMaterials, setTempMaterials] = useState([]);
  const [approver, setApprover] = useState([]);
  const [categoryNotSelect, setCategoryNotSelect] = useState(true);
  const [selectedApprover, setSelectedApprover] = useState('');
  const [purpose, setPurpose] = useState('');
  const [desc, setDesc] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [categoryPicker, setCategoryPicker] = useState(false);
  const [categorySearch, setCategorySearch] = useState('');
  const [editData, setEditData] = useState(false);

  //Validation useState
  const [dueDateValid, setDueDateValid] = useState('#969696');
  const [approverValid, setApproverValid] = useState('#969696');
  const [purposeValid, setPurposeValid] = useState('#969696');
  const [priorityValid, setPriorityValid] = useState('#969696');

  const moment = require('moment');

  useEffect(() => {
    getCategories();
    getManagerNames();
  }, []);

  // useEffect(() => {
  //   console.log('materials / tempMaterials changed');
  // }, [materials, tempMaterials]);

  useFocusEffect(
    useCallback(() => {
      setMaterials(Config.selectedMaterials);
      setTempMaterials(Config.selectedMaterials);
    }, [Config.selectedMaterials]),
  );

  async function getCategories() {
    try {
      axiosClientInterceptors({
        method: 'get',
        url: `/getcategories`,
      })
        .then(response => {
          setMatCatlist([]);
          setMatCatlist(response.data.Category);
        })
        .then(res => setShowLoader(false));
    } catch (e) {
      console.log(e);
    }
  }

  async function getManagerNames() {
    try {
      axiosClientInterceptors({
        method: 'get',
        url: `/getmanagernames`,
      }).then(response => {
        console.log(response.data.Project_Managers);
        setApprover(response.data.Project_Managers);
      });
    } catch (e) {
      console.log(e);
    }
  }

  const [priority, setPriority] = useState('');
  const [isDatePickerVisible, setDatePickerVisibility] = useState();

  const showDatePicker = () => {
    setDatePickerVisibility(true);
  };
  const hideDatePicker = () => {
    setDatePickerVisibility(false);
  };
  const handleConfirm = date => {
    // console.warn('A date has been picked: ', date);
    setDueDate(moment(date).format('DD/MM/YYYY'));
    setDueDateValid('#969696');
    hideDatePicker();
  };

  function validData() {
    var flag = 1;
    if (materials.length === 0) {
      ToastAndroid.show('Add materials', ToastAndroid.CENTER);
      flag = 0;
    }

    if (
      dueDate === '' ||
      purpose === '' ||
      selectedApprover === '0' ||
      priority === '0' ||
      selectedApprover === '' ||
      priority === ''
    ) {
      if (JSON.stringify(dueDate) === '""') {
        setDueDateValid('red');
      }
      if (purpose === '') {
        setPurposeValid('red');
      }
      if (selectedApprover === '0' || selectedApprover === '') {
        setApproverValid('red');
      }
      if (priority === '0' || priority === '') {
        setPriorityValid('red');
      }
      flag = 0;
    }

    function validateMatSpecData() {
      // Remove
      materials.map(materialUom => {
        if (materialUom.uom === '' || materialUom.material_name === '0') {
          ToastAndroid.show(
            'Enter details for all materials',
            ToastAndroid.CENTER,
          );
          flag = 0;
          return false;
        }
      });
      if (flag === 1) {
        return true;
      } else {
        return false;
      }
    }

    if (flag === 1) {
      setModalVisible(true);
      !modalVisible && submitForm();
    } else {
      ToastAndroid.show('Enter all fields', ToastAndroid.CENTER);
    }
  }

  function submitForm() {
    setShowLoader(true);
    const postData = {
      project_id: props.route.params.projectId,
      project_name: props.route.params.projectName,
      material_category: materialCategory,
      material_description: desc,
      priority: priority,
      purpose: purpose,
      due_date: JSON.stringify(dueDate).slice(1, 11),
      approved_by: selectedApprover,
      materials: materials,
      site_engineer: Config.SiteEnggId,
    };
    const dataToBeSent = postData;
    axiosClientInterceptors
      .post(`/addrequests`, dataToBeSent)
      .then(function (response) {
        console.log('dataToBeSent', dataToBeSent);
        console.log('addrequests', response.data);
        setShowLoader(false);
        setModalVisible(true);
      });
    // setModalVisible(true);
  }

  const onCategoryChange = itemValue => {
    setCategoryPicker(false);
    setCategoryNotSelect(false);
    if (categoryNotSelect) {
      Config.selectedMaterials = [];
      setMaterialCategory(itemValue);
    } else {
      materialCategory !== itemValue &&
        Alert.alert('Warning', 'Changing category will reset materials data.', [
          {
            text: 'Cancel',
          },
          {
            text: 'Ok',
            onPress: () => {
              setMaterialCategory(itemValue);
              setMaterials([]);
              Config.selectedMaterials = [];
            },
          },
        ]);
    }
  };

  const renderItem = ({item}) => {
    if (categorySearch.length === 0) {
      return <Item title={item.category_name} />;
    } else {
      if (
        item.category_name.toLowerCase().includes(categorySearch.toLowerCase())
      ) {
        return <Item title={item.category_name} />;
      } else {
        return <View />;
      }
    }
  };

  const Item = ({title}) => (
    <TouchableOpacity
      onPress={() => onCategoryChange(title)}
      style={styles.pickerRow}>
      <Text style={styles.matCatTxt}>{title}</Text>
      {materialCategory === title ? (
        <Text>Y</Text>
      ) : (
        <Text>N</Text>
      )}
    </TouchableOpacity>
  );

  const SelectedMaterials = () => {
    return (
      <View style={{marginBottom: 10}}>
        {materials.map((obj, index) => {
          return (
            <TouchableOpacity
              style={styles.martialRows}
              onPress={() => {
                setEditData(obj);
              }}
              key={index}>
              <View style={styles.materialDetsTxt}>
                <Text style={styles.matNameTxt}>{obj.material_name}</Text>
                <Text style={styles.uomTxt}>
                  UOM: {obj.uom}
                  <Text style={{color: '#F0BF19'}}>{'  '}Edit Details</Text>
                </Text>
              </View>
              <View style={[styles.qtyVue, {justifyContent: 'center'}]}>
                <Text style={styles.qtyTxt}>{obj.quantity}</Text>
              </View>
            </TouchableOpacity>
          );
        }, [])}
      </View>
    );
  };

  function updateData(data) {
    let dataArr = tempMaterials;
    if (data.ticked) {
      if (
        dataArr.filter(val => val.material_name === data.material_name).length >
        0
      ) {
        const index = dataArr.findIndex(
          obj => obj.material_name === data.material_name,
        );
        dataArr[index] = data;
      } else {
        dataArr.push(data);
      }
    } else {
      setEditData(false);
      if (
        dataArr.filter(val => val.material_name === data.material_name).length >
        0
      ) {
        const index = dataArr.findIndex(
          obj => obj.material_name === data.material_name,
        );
        dataArr.splice(index, 1);
      }
    }
    setTempMaterials(dataArr);
  }

  function saveToStorage() {
    setEditData(false);
    Config.selectedMaterials = tempMaterials;
    setMaterials(tempMaterials);
  }

  return (
    <SafeAreaView
      style={{backgroundColor: '#F8F8F8'}}
      keyboardShouldPersistTaps="always"
      keyboardDismissMode="on-drag">
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          // navigation.navigate('Home');
          // setModalVisible(false);
          props.navigation.goBack(null);
        }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Image
              style={styles.successImg}
              source={require('../../assets/images/success.png')}
            />
            <Text style={styles.modalText}>Order sent successfully!</Text>
            <TouchableOpacity
              onPress={() => {
                // setModalVisible(false);
                props.navigation.goBack(null);
              }}
              title="sendOrder">
              <LinearGradient
                colors={['#FFE185', '#F6BA00']}
                start={{x: 0, y: 1}}
                end={{x: 1, y: 0}}
                style={[styles.goBackBtn, {width: windowWidth / 1.5}]}>
                <Text style={[styles.text, {fontSize: 16, color: 'black'}]}>
                  Go Back
                </Text>
              </LinearGradient>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={categoryPicker}
        onRequestClose={() => {
          setCategoryPicker(false);
        }}>
        <View style={styles.pickerBg}>
          <View style={styles.pickerVue}>
            <View style={styles.pickerHeader}>
              <Text style={[styles.matCatTxt, {fontFamily: 'Gilroy-Bold'}]}>
                Select Material Category
              </Text>
              <TouchableOpacity onPress={() => setCategoryPicker(false)}>
              </TouchableOpacity>
            </View>
            <View style={styles.pickerSearchVue}>
              <TextInput
                placeholder="Search by category name"
                style={[styles.matCatTxt, {flex: 1}]}
                onChangeText={setCategorySearch}
              />
            </View>
            <FlatList
              data={matCatList}
              renderItem={renderItem}
              keyExtractor={item => item.id}
              showsVerticalScrollIndicator={false}
              // keyboardShouldPersistTaps={'handled'}
            />
          </View>
        </View>
      </Modal>

      <Modal
        animationType="fade"
        transparent={true}
        visible={editData.material_name?.length > 0}
        onRequestClose={() => {
          setEditData(false);
        }}>
        <View style={styles.pickerBg}>
          <View style={styles.editPopup}>
            <View style={styles.editPopupHeader}>
              <Text style={styles.editMatNameTxt}>
                {editData.material_name}
              </Text>
              <TouchableOpacity onPress={() => setEditData(false)}>
              </TouchableOpacity>
            </View>

            <MaterialSelection
              materialName={editData.material_name}
              uom={editData.uomList}
              ticked={true}
              updateData={data => updateData(data)}
              saveToStorage={() => saveToStorage()}
            />
          </View>
        </View>
      </Modal>

      <ScrollView>
        <View style={styles.parentCard}>
          <View style={styles.projectInfo}>
            <View style={styles.ProjectInfoDivs}>
              <Text style={[styles.text, styles.lableText]}>Project Name</Text>
              <Text style={styles.infoText}>
                {props.route.params.projectName}
              </Text>
            </View>
            <View style={styles.ProjectInfoDivs}>
              <Text style={[styles.text, styles.lableText]}>Project Stage</Text>
              <Text style={styles.infoText}>
                {props.route.params.projectStage}
              </Text>
            </View>
          </View>

          <Text style={[styles.text, {paddingLeft: 10, marginTop: 15}]}>
            Material Category
          </Text>
          <View style={[styles.inputBorder, {paddingLeft: 5}]}>
            <TouchableOpacity
              style={styles.dropdnBtn}
              onPress={() => setCategoryPicker(true)}>
              <Text style={styles.matCatTxt}>
                {materialCategory ? (
                  materialCategory
                ) : (
                  <Text style={{color: '#969696'}}>
                    Select material category
                  </Text>
                )}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.MateriallabelDiv}>
            <Text
              style={[
                styles.text,
                styles.materialDetsTxt,
                {
                  paddingLeft: 10,
                },
              ]}>
              Material Name
            </Text>
            <View style={styles.qtyVue}>
              <Text style={styles.text}>Qty</Text>
            </View>
          </View>
          {categoryNotSelect ? (
            <TouchableOpacity
              onPress={() => setCategoryPicker(true)}
              activeOpacity={1}
              style={[styles.inputBorder, styles.infoMsg]}>
              <Text style={[styles.text, styles.lableText]}>
                Select material category, then add material
              </Text>
            </TouchableOpacity>
          ) : (
            <View>
              <SelectedMaterials />
              <TouchableOpacity
                onPress={() => {
                  props.navigation.navigate('chooseMaterials', {
                    category: materialCategory,
                  });
                }}
                style={styles.addMatBtn}>
                <Text style={styles.text}> Add Materials</Text>
              </TouchableOpacity>
            </View>
          )}

          <View
            style={{
              alignContent: 'flex-start',
              marginTop: 25,
              marginBottom: 15,
            }}>
            <Text style={[styles.text, {paddingLeft: 10}]}>Comments</Text>
            <TextInput
              style={[styles.inputBorder, {color: 'black', height: 150}]}
              multiline={true}
              numberOfLines={10}
              textAlignVertical={'top'}
              placeholder="Additional comments (if any)."
              placeholderTextColor="#969696"
              onChange={e => setDesc(e.nativeEvent.text)}
            />
          </View>
          <View style={{flexDirection: 'row', flex: 1}}>
            <View>
              <Text style={[styles.text, {paddingLeft: 10}]}>Due Date</Text>
              <TouchableOpacity
                style={[
                  styles.inputBorder,
                  {
                    width: windowWidth / 2.7,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  },
                ]}
                onPress={showDatePicker}>
                <Text style={styles.text}>
                  <Text
                    style={{
                      color: dueDateValid === 'red' ? 'red' : '#969696',
                    }}>
                    {dueDate !== '' ? (
                      <Text style={{color: 'black'}}>{dueDate}</Text>
                    ) : (
                      'Select Date'
                    )}
                  </Text>
                </Text>
                <Image
                  style={styles.cal}
                  source={require('../../assets/images/Calendar.png')}
                />
              </TouchableOpacity>
              <DateTimePickerModal
                isVisible={isDatePickerVisible}
                mode="date"
                minimumDate={new Date()}
                onConfirm={handleConfirm}
                onCancel={hideDatePicker}
              />
            </View>
            <View>
              <Text style={[styles.text, {paddingLeft: 10}]}>Priority</Text>
              <View
                style={[
                  styles.inputBorder,
                  {
                    width: windowWidth / 2.8,
                    paddingLeft: 0,
                    borderColor: priorityValid,
                  },
                ]}>
                <Picker
                  style={{
                    alignItems: 'center',
                    flex: 1,
                  }}
                  placeholder="Select Priority"
                  selectedValue={priority}
                  dropdownIconColor="#FCC314"
                  dropdownIconRippleColor="#FCC314"
                  mode={'dropdown'}
                  onValueChange={(itemValue, itemIndex) => {
                    itemValue !== '0' && setPriority(itemValue);
                  }}>
                  <Picker.Item
                    label="Select"
                    value="0"
                    style={styles.text}
                    color={'#969696'}
                  />
                  <Picker.Item label="High" value="High" style={styles.text} />
                  <Picker.Item
                    label="Medium"
                    value="Medium"
                    style={styles.text}
                  />
                  <Picker.Item label="Low" value="Low" style={styles.text} />
                </Picker>
              </View>
            </View>
          </View>
          <View>
            <Text style={[styles.text, {paddingLeft: 10, marginTop: 15}]}>
              Approver
            </Text>
            <View
              style={[
                styles.inputBorder,
                {borderColor: approverValid, paddingLeft: 0},
              ]}>
              <Picker
                style={{width: '100%'}}
                placeholder="Select Approver"
                dropdownIconColor="#FCC314"
                dropdownIconRippleColor="#FCC314"
                selectedValue={selectedApprover}
                mode={'dropdown'}
                onValueChange={(itemValue, itemIndex) => {
                  itemValue !== '0' && setSelectedApprover(itemValue);
                }}>
                <Picker.Item
                  label="Select Approver"
                  value="0"
                  style={styles.text}
                  color={'#969696'}
                />
                {approver.map(approver => (
                  <Picker.Item
                    label={approver.name}
                    value={approver._id}
                    style={styles.text}
                  />
                ))}
              </Picker>
            </View>
          </View>
          <View>
            <View
              style={{
                alignContent: 'flex-start',
                marginTop: 15,
                marginBottom: 15,
              }}>
              <Text style={[styles.text, {paddingLeft: 10}]}>Purpose</Text>
              <TextInput
                style={[
                  styles.inputBorder,
                  {color: 'black', borderColor: purposeValid},
                ]}
                placeholder="Enter the purpose"
                placeholderTextColor="#969696"
                onChange={e => {
                  setPurpose(e.nativeEvent.text);
                  e.nativeEvent.text !== '' && setPurposeValid('#969696');
                }}
              />
            </View>
          </View>
          <TouchableOpacity title="sendOrder" onPress={() => validData()}>
            <LinearGradient
              colors={['#FFE185', '#F6BA00']}
              start={{x: 0, y: 1}}
              end={{x: 1, y: 0}}
              style={styles.gradientBtn}>
              <Text style={[styles.text, {fontSize: 15, color: 'black'}]}>
                SEND ORDER
              </Text>
            </LinearGradient>
          </TouchableOpacity>
        </View>
      </ScrollView>
      {showLoader && (
        <ActivityIndicator size="large" color="#ffcd30" style={styles.loader} />
      )}
    </SafeAreaView>
  );
}

export default OrderMaterials;

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  text: {
    color: 'black',
    fontFamily: 'Gilroy-Medium',
    fontSize: 13,
  },
  parentCard: {
    margin: 15,
    marginBottom: 25,
    backgroundColor: 'white',
    padding: 15,
    borderRadius: 5,
  },
  projectInfo: {
    backgroundColor: '#FFF5D6',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: 2,
    height: 80,
    marginBottom: 15,
    paddingLeft: 10,
  },
  ProjectInfoDivs: {
    height: 60,
    justifyContent: 'space-around',
    width: windowWidth / 2.8,
    alignItems: 'flex-start',
  },
  MateriallabelDiv: {
    flex: 1,
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#969696',
    paddingBottom: 15,
    marginBottom: 15,
    marginTop: 25,
    marginHorizontal: 10,
    justifyContent: 'space-between',
  },
  inputBorder: {
    borderWidth: 0.5,
    borderRadius: 2,
    borderColor: '#969696',
    justifyContent: 'center',
    paddingLeft: 15,
    margin: 10,
    height: 50,
    fontFamily: 'Gilroy-Medium',
    fontSize: 13,
  },
  addBtn: {
    height: 25,
    width: 25,
    resizeMode: 'contain',
  },
  cal: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
    alignSelf: 'center',
    marginRight: 10,
  },
  dropdownArrow: {
    height: 25,
    resizeMode: 'contain',
    justifyContent: 'center',
  },
  goBackBtn: {
    width: windowWidth / 1.28,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    margin: 10,
  },
  gradientBtn: {
    flex: 1,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 2,
    margin: 10,
  },
  lableText: {
    color: '#a1a1a1',
    fontSize: 11,
  },
  infoText: {
    color: 'black',
    fontFamily: 'Gilroy-Bold',
    fontSize: 15,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    paddingHorizontal: 35,
    paddingVertical: 25,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: windowWidth / 1.2,
  },
  modalText: {
    marginBottom: 25,
    textAlign: 'left',
    color: 'black',
    fontFamily: 'Gilroy-Bold',
    fontSize: 20,
  },
  successImg: {
    width: 60,
    height: 60,
    marginBottom: 15,
  },
  loader: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: windowHeight / 2,
  },
  infoMsg: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: 0,
    paddingTop: 15,
    paddingBottom: 15,
    backgroundColor: '#ffebeb',
    borderWidth: 0,
  },
  addMatBtn: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF3CD',
    height: 45,
    marginHorizontal: 10,
    borderRadius: 2,
  },
  matCatTxt: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 15,
  },
  dropdnBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 10,
    paddingRight: 15,
    alignItems: 'center',
  },
  pickerBg: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.6)',
    paddingVertical: 15,
  },
  pickerVue: {
    width: windowWidth / 1.12,
    flex: 1,
    backgroundColor: 'white',
    borderRadius: 2,
    padding: 25,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  pickerSearchVue: {
    borderRadius: 2,
    height: 45,
    marginVertical: 15,
    paddingHorizontal: 5,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
  },
  pickerRow: {
    paddingHorizontal: 8,
    paddingVertical: 15,
    borderTopWidth: 1,
    borderColor: '#E3E3E3',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pickerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  martialRows: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 10,
    justifyContent: 'space-between',
    marginVertical: 8,
  },
  qtyTxt: {
    textAlign: 'center',
    fontFamily: 'Gilroy-Medium',
    fontSize: 13,
    marginBottom: 8,
  },
  matNameTxt: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 14,
    width: windowWidth / 2,
  },
  uomTxt: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#969696',
    marginVertical: 6,
  },
  editPopup: {
    width: windowWidth / 1.1,
    backgroundColor: 'white',
  },
  editMatNameTxt: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
  },
  editPopupHeader: {
    display: 'flex',
    flexDirection: 'row',
    margin: 15,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  qtyVue: {
    width: 80,
    alignItems: 'center',
  },
  materialDetsTxt: {
    flex: 1,
    marginRight: 15,
    fontFamily: 'Gilroy-SemiBold',
  },
});
