import React, {useEffect, useState} from 'react';
import LinearGradient from 'react-native-web-linear-gradient';
import DocumentPicker from 'react-native-document-picker';
import RNFetchBlob from 'react-native-blob-util';
import {
  ScrollView,
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  TextInput,
  ToastAndroid,
  Alert,
  ActivityIndicator,
  Modal,
} from 'react-native';
import MaterialDetails from './MaterialDetails';
import axiosClientInterceptors from '../BasicComponents/Auth';
import {SafeAreaView} from 'react-native-safe-area-context';
import Config from '../BasicComponents/Config';

function MaterialReceived(props, {navigate}) {
  const [materials, setMaterials] = useState([]);
  const [editable, setEditable] = useState(true);
  const [invoiceNum, setInvoiceNum] = useState('');
  const [totalAmt, setTotalAmt] = useState('');
  const [comments, setComments] = useState('');
  const [invoiceNumBorder, setInvoiceNumBorder] = useState('#969696');
  const [totalAmtBorder, setTotalAmtBorder] = useState('#969696');
  const [invoicePhotoBorder, setInvoicePhotoBorder] = useState('#969696');
  const [invoicePhoto, setInvoicePhoto] = useState('#969696');
  const [invoiceImageFileNames, setInvoiceImageFileNames] = useState([]);
  const [imgFies, setImgFiles] = useState([]);
  const [materialDetails, setMaterialDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const materialInfo = (counter, materialName, received, diff, img, id) => {
    console.log({
      material_name: materialName,
      difference: diff,
      received: received,
      _id: id,
    });
    const materialDetsArray = materialDetails;
    var imgArray = imgFies;
    materialDetsArray.splice(counter, 1, {
      material_name: materialName,
      difference: diff,
      received: received,
      _id: id,
    });
    imgArray.splice(counter, 1, {
      images: img,
      id: id,
    });
    setMaterialDetails([]);
    setImgFiles([]);
    setMaterialDetails(materialDetsArray);
    setImgFiles(imgArray);
  };

  useEffect(() => {
    if (props.header === 'Received') {
      setEditable(false);
    }
  });

  async function setReceivedStatus() {
    let request_Id = props._id;
    var materialArray = [];
    materialArray = materialDetails;

    materialArray.push(
      {
        invoice_number: invoiceNum,
      },
      {invoice_amount: totalAmt},
      {comments: comments},
    );

    setMaterials([]);
    setMaterials(materialArray);
    if (invoiceNum && totalAmt && invoiceImageFileNames !== undefined) {
      if (invoiceImageFileNames.length > 0) {
        UploadImageData();
        setShowLoader(true);
        // setModalVisible(true);
        axiosClientInterceptors
          .post(
            `/materialreceived?_id=${props.route.params._id}`,
            materialArray,
          )
          .then(response => {
            setModalVisible(true);
          })
          .catch(err => console.log(err));
      } else {
        setInvoicePhotoBorder('red');
      }
    } else {
      if (invoiceNum === '') {
        setInvoiceNumBorder('red');
      }
      if (totalAmt === '') {
        setTotalAmtBorder('red');
      }
      if (invoiceImageFileNames.length < 1) {
        setInvoicePhotoBorder('red');
      }
    }
  }

  function submitRecon() {
    setReceivedStatus();
  }

  const invoiceimagepicker = async () => {
    setInvoicePhoto([]);
    setInvoiceImageFileNames([]);
    ToastAndroid.show(
      'Tap and hold to select multiple images',
      ToastAndroid.LONG,
    );
    var imgNames = [];
    try {
      const res = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.images],
      });
      setInvoicePhoto(res);
      for (let i = 0; i < res.length; i++) {
        imgNames.push(res[i].name);
      }
      setInvoiceImageFileNames(imgNames);
      setInvoicePhotoBorder('#969696');
    } catch (err) {
      if (DocumentPicker.isCancel(err)) {
        alert('Select an image to upload.');
      } else {
        throw err;
      }
    }
  };

  async function sendImages(imageFiles, url, id) {
    var dataObj = [];

    for (let i = 0; i < imageFiles.length; i++) {
      dataObj.push(RNFetchBlob.wrap(imageFiles[i].uri));
    }

    dataObj.map((img, index) => {
      uploadAPI(img, imageFiles[index].name, imageFiles[index].type);
    });

    let request_Id = props.req_id;

    async function uploadAPI(imgData, fileName, fileType) {
      let ret = await RNFetchBlob.fetch(
        'POST',
        `${url}?_id=${id}`,
        {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': Config.authToken,
        },
        [
          {
            name: 'image',
            filename: fileName,
            type: fileType,
            data: imgData,
          },
        ],
      )
        .then(response => {
          console.log(`uploadAPI(${JSON.stringify(imgData)})`, response);
        })
        .catch(error => {
          console.log(`uploadAPI(${JSON.stringify(imgData)})`, error);
        });
    }
  }

  const UploadImageData = async () => {
    //Invoice Images
    const invoiceUp =
    Config.REACT_DATA_API_URL + Config.REACT_APP_ENVIRONMENT + '/uploadinvoice';
    sendImages(invoicePhoto, invoiceUp, props.route.params._id);

    // Material Images
    const materialUp =
    Config.REACT_DATA_API_URL + Config.REACT_APP_ENVIRONMENT + '/receivedimages';
    imgFies.map(img => {
      sendImages(img.images, materialUp, img.id);
    });
  };

  return (
    <SafeAreaView>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          props.navigation.pop(2);
        }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Image
              style={styles.successImg}
              source={require('../../assets/images/success.png')}
            />
            <Text style={styles.modalText}>Order sent for reconciliation!</Text>
            <TouchableOpacity
              onPress={() => {
                props.navigation.pop(2);
              }}
              title="sendOrder">
              <LinearGradient
                colors={['#FFE185', '#F6BA00']}
                start={{x: 0, y: 1}}
                end={{x: 1, y: 0}}
                style={[styles.goBackBtn, {width: windowWidth / 1.5}]}>
                <Text style={[styles.text, {fontSize: 16, color: 'black'}]}>
                  Got it
                </Text>
              </LinearGradient>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
      <ScrollView
        // keyboardShouldPersistTaps={'always'}
        style={{backgroundColor: '#F8F8F8'}}>
        <View style={styles.cardView}>
          {props.route.params.materials.map((matName, index) => (
            <MaterialDetails
              key={index}
              counter={index}
              materialInfo={materialInfo}
              materialName={matName.material_name}
              materialQty={matName.quantity}
              specs={matName.uom}
              id={matName._id}
              editable={editable}
              received={matName.received}
            />
          ))}
          <View>
            <View
              style={[
                styles.header,
                {
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                },
              ]}>
              <View>
                <View style={styles.invoiceEntry}>
                  <View style={{width: '47%'}}>
                    <Text style={styles.lableText}>Invoice Number</Text>
                    <TextInput
                      keyboardType="numeric"
                      onChange={e => (
                        setInvoiceNum(e.nativeEvent.text),
                        setInvoiceNumBorder('#969696')
                      )}
                      style={[
                        styles.inputBorder,
                        {
                          borderColor: invoiceNumBorder,
                        },
                      ]}
                      placeholder="Enter Number"
                    />
                    {invoiceNumBorder === 'red' && (
                      <Text style={styles.validationText}>Required</Text>
                    )}
                  </View>
                  <View style={{width: '47%'}}>
                    <Text style={styles.lableText}>Total Amount</Text>
                    <TextInput
                      keyboardType="numeric"
                      onChange={e => (
                        setTotalAmt(e.nativeEvent.text),
                        setTotalAmtBorder('#969696')
                      )}
                      style={[
                        styles.inputBorder,
                        {
                          borderColor: totalAmtBorder,
                        },
                      ]}
                      placeholder="Enter Amount"
                    />
                    {totalAmtBorder === 'red' && (
                      <Text style={styles.validationText}>Required</Text>
                    )}
                  </View>
                </View>

                <View style={[styles.header, styles.uploadInvoiceVue]}>
                  <Text style={styles.lableText}>Upload Invoice Photo</Text>
                  <View
                    style={{
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                    <TouchableOpacity
                      onPress={invoiceimagepicker}
                      style={{flex: 1}}>
                      <View
                        style={[
                          styles.inputBorder,
                          {
                            borderColor: invoicePhotoBorder,
                            flexDirection: 'row',
                          },
                        ]}>
                        <Text
                          style={[
                            styles.infoText,
                            {
                              fontFamily: 'Gilroy-Medium',
                              fontSize: 15,
                              color: '#969696',
                            },
                          ]}>
                          {invoiceImageFileNames.length} images selected
                        </Text>
                        <Image
                          style={styles.upload}
                          source={require('../../assets/images/Upload.png')}
                        />
                      </View>
                      {invoicePhotoBorder === 'red' && (
                        <Text style={styles.validationText}>Required</Text>
                      )}
                    </TouchableOpacity>
                  </View>
                </View>
              </View>

              <Text style={styles.lableText}>Comments</Text>
              <View style={[styles.inputBorder, styles.commentVue]}>
                <TextInput
                  onChange={e => setComments(e.nativeEvent.text)}
                  style={{
                    height: 100,
                    fontFamily: 'Gilroy-Medium',
                    width: '100%',
                  }}
                  placeholder="Enter Comments Here"
                  multiline={true}
                  numberOfLines={5}
                  textAlignVertical={'top'}
                />
              </View>
            </View>
          </View>
        </View>
        <TouchableOpacity
          onPress={() => !showLoader && submitRecon()}
          style={styles.submitBtn}>
          <LinearGradient
            colors={['#FFE185', '#F6BA00']}
            start={{x: 0, y: 1}}
            end={{x: 1, y: 0}}
            style={styles.buttons}>
            <Text
              style={{
                fontSize: 15,
                fontFamily: 'Gilroy-SemiBold',
                color: 'black',
              }}>
              Order Received
            </Text>
          </LinearGradient>
        </TouchableOpacity>
        {showLoader && (
          <ActivityIndicator
            size="large"
            color="#ffcd30"
            style={styles.loader}
          />
        )}
      </ScrollView>
    </SafeAreaView>
  );
}

export default MaterialReceived;

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  cardView: {
    backgroundColor: 'white',
    flex: 1,
    padding: 15,
    borderRadius: 8,
    alignSelf: 'center',
    marginHorizontal: 15,
  },
  modalText: {
    marginBottom: 18,
    textAlign: 'left',
    color: 'black',
    fontFamily: 'Gilroy-Bold',
    fontSize: 20,
  },
  goBackBtn: {
    width: windowWidth / 1.28,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    margin: 10,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    paddingHorizontal: 35,
    paddingVertical: 15,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: windowWidth / 1.2,
  },
  modalButtonClose: {
    backgroundColor: 'red',
    marginTop: 15,
    // objectFit: 'contain',
    height: 45,
    width: windowWidth / 2,
    borderRadius: 5,
  },
  lableText: {
    fontSize: 13,
    marginTop: 18,
    fontFamily: 'Gilroy-Medium',
  },
  infoText: {
    color: 'black',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 15,
  },
  inputBorder: {
    borderWidth: 0.5,
    borderRadius: 5,
    borderColor: '#969696',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
    borderWidth: 0.5,
    marginTop: 10,
    height: 50,
    fontFamily: 'Gilroy-Medium',
  },
  row1: {
    flexDirection: 'row',
    width: windowWidth / 1.25,
    justifyContent: 'space-between',
  },
  closeBtnDiv: {
    width: windowWidth / 1.25,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttons: {
    backgroundColor: '#ffd142',
    width: '100%',
    height: 55,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 25,
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    // marginBottom: 15,
    alignItems: 'center',
  },
  upload: {
    height: 23,
    width: 23,
    resizeMode: 'contain',
  },
  container: {
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  validationText: {
    fontSize: 10,
    color: 'red',
    fontFamily: 'Gilroy-Medium',
    marginLeft: 5,
  },
  loader: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  uploadInvoiceVue: {
    height: 100,
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginVertical: 5,
    width: '100%',
  },
  invoiceEntry: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  commentVue: {
    paddingHorizontal: 8,
    alignItems: 'flex-start',
    height: 100,
    marginBottom: 15,
    width: '100%',
  },
  submitBtn: {
    marginHorizontal: 15,
    marginBottom: 35,
    marginTop: -5,
  },
  successImg: {
    width: 65,
    height: 65,
    marginVertical: 10,
  },
  text: {
    color: 'black',
    fontFamily: 'Gilroy-Medium',
    fontSize: 13,
  },
});
