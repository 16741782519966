import React, {useEffect, useState} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Image,
  ToastAndroid,
  TextInput,
} from 'react-native';
import DocumentPicker from 'react-native-document-picker';

function MaterialDetails(props) {
  const [qtyValue, setQtyValue] = useState(0);
  const [imageFileNames, setImageFileName] = useState([]);
  const [pic, setPic] = useState([]);

  useEffect(() => {
    handleChange(props.received, pic);
    console.log(props);
  }, []);

  const imagepicker = async () => {
    setPic([]);
    setImageFileName([]);
    ToastAndroid.show(
      'Tap and hold to select multiple images',
      ToastAndroid.LONG,
    );
    var imgName = [];
    try {
      const res = await DocumentPicker.pickMultiple({
        type: [DocumentPicker.types.images],
      });

      setPic(res);
      for (let i = 0; i < res.length; i++) {
        imgName.push(res[i].name);
      }
      setImageFileName(imgName);

      handleChange(qtyValue, res);
    } catch (err) {
      if (DocumentPicker.isCancel(err)) {
        ToastAndroid.show('No images selected', ToastAndroid.CENTER);
      } else {
        throw err;
      }
    }
  };

  function handleChange(value, pic) {
    // const difference = value - props.materialQty;
    const difference = (value | 0) - props.materialQty;

    console.log('value', value);
    const receivedValue = value;

    props.materialInfo(
      props.counter,
      props.materialName,
      receivedValue,
      difference,
      pic,
      props.id,
    );
  }

  function updateTotal(value) {
    const prevReceived = props.received > 0 ? props.received : 0;

    const inputValue = value > 0 ? value : 0;

    const totalVal = +prevReceived + +inputValue;

    setQtyValue(totalVal);
    handleChange(totalVal, pic);
  }

  return (
    <View style={styles.row}>
      <View style={styles.thContainer}>
        <View style={styles.th}>
          <View style={[styles.thItems, {width: '50%'}]}>
            <Text style={styles.labels}>Material Name</Text>
            <Text style={styles.inputs}>{props.materialName}</Text>
          </View>
          <View style={[styles.thItems, {width: '20%', alignItems: 'center'}]}>
            <Text style={styles.labels}>Ordered</Text>
            <Text style={styles.inputs}>{props.materialQty}</Text>
          </View>
          <View style={[styles.thItems, {width: '20%', alignItems: 'center'}]}>
            <Text style={styles.labels}>UOM</Text>
            <Text style={styles.inputs}>{props.specs}</Text>
          </View>
        </View>
        <View style={styles.tb}>
          <View style={styles.tbInputs}>
            <Text style={styles.labels}>Received Materials' Photo</Text>
            <View
              style={[
                styles.header,
                {
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                },
              ]}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <TouchableOpacity onPress={imagepicker}>
                  <View
                    style={[
                      styles.inputBorder,
                      {
                        flexDirection: 'row',
                      },
                    ]}>
                    <Text
                      style={[
                        styles.inputs,
                        {
                          fontFamily: 'Gilroy-Medium',
                          fontSize: 15,
                          color: '#969696',
                        },
                      ]}>
                      {imageFileNames.length} selected
                    </Text>
                    <Image
                      style={styles.upload}
                      source={require('../../assets/images/Upload.png')}
                    />
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          </View>
          <View style={[styles.tbInputs, {flex: 1}]}>
            <Text style={styles.labels}>Total Qty Received</Text>
            <View
              style={[
                styles.inputBorder,
                {
                  flexDirection: 'row',
                },
              ]}>
              <Text style={[styles.inputs, {marginTop: 0, color: '#909090'}]}>
                {props.received !== undefined ? props.received : 0} +
              </Text>
              <TextInput
                keyboardType="numeric"
                selectionColor={'#FCC216'}
                onChangeText={value => updateTotal(value)}
                style={styles.textInput}
              />
            </View>
            {/* <NumericInput
              type="plus-minus"
              minValue={0}
              rounded
              totalHeight={50}
              onChange={value => {
                setQtyValue(value), handleChange(value, pic);
              }}
              borderColor="white"
              rightButtonBackgroundColor="#FEEDB9"
              leftButtonBackgroundColor="#FEEDB9"
            /> */}
          </View>
        </View>
      </View>
    </View>
  );
}

export default MaterialDetails;

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
  },
  thContainer: {
    borderWidth: 1,
    borderColor: '#F5F5F5',
    width: '100%',
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 10,
    justifyContent: 'space-between',
  },
  th: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  tb: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15,
    width: '100%',
  },
  thItems: {},
  labels: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#969696',
    marginBottom: 8,
  },
  inputs: {fontFamily: 'Gilroy-SemiBold', fontSize: 15, color: '#221E1F'},
  inputBorder: {
    borderWidth: 0.5,
    borderRadius: 5,
    borderColor: '#969696',
    justifyContent: 'space-between',
    padding: 15,
    borderWidth: 0.5,
    height: 50,
    flex: 1,
  },
  textInput: {
    width: '65%',
    fontFamily: 'Gilroy-Medium',
    fontSize: 15,
    padding: 0,
  },
  upload: {
    height: 18,
    width: 18,
    resizeMode: 'contain',
    marginLeft: 18,
  },
  tbInputs: {width: '60%'},
});
