import React, {useEffect, useState, useRef} from 'react';
import LinearGradient from 'react-native-web-linear-gradient';
import {
  SafeAreaView,
  ScrollView,
  RefreshControl,
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import {useFocusEffect} from '@react-navigation/native';
import axiosClientInterceptors from '../BasicComponents/Auth';
import Config from '../BasicComponents/Config';

function ProjectDetails(props) {
  const [projectDetails, setProjectDetails] = useState([]);
  const [viewMore, setViewMore] = useState(false);
  const [requests, setRequests] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [projStage, setProjStage] = useState('');
  const [noOfReq, setNoOfReq] = useState(0);

  const reqStatList = [
    'New Request',
    'Approved',
    'Rejected',
    'PO Created',
    'Partially Received',
    'Material Received',
    'Closed',
  ];

  const scrollRef = useRef();

  async function getApiData() {
    const projectId = props.route.params.projectId;
    console.log(`/getseprojects?_id=${projectId}`);
    try {
      axiosClientInterceptors({
        method: 'get',
        url: `/getseprojects?_id=${projectId}`,
      }).then(response => {
        console.log('response.data.projects', response.data.result[0]);
        setProjectDetails(response.data.result[0]);
        setRequests(response.data.result[0].requests);
        setShowLoader(false);
        setNoOfReq(response.data.result[0].totalRequest);
        // console.log('project_stage', response.data.result[0].project_stage);
        setProjStage(response.data.result[0].project_stage);
      });
    } catch {
      console.log('err');
    }
  }

  useFocusEffect(
    React.useCallback(() => {
      setShowLoader(true);
      getApiData();
    }, []),
  );

  const wait = timeout => {
    return new Promise(resolve => setTimeout(resolve, timeout));
  };

  const [refreshing, setRefreshing] = React.useState(false);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    getApiData();

    wait(2000).then(() => setRefreshing(false));
  }, []);

  function requestCard(req) {
    let priorityColor;
    let statusBgColor;
    let statusTextColor;
    switch (req.priority) {
      case 'High':
        priorityColor = '#FE3E3E';
        break;
      case 'Medium':
        priorityColor = '#FC8314';
        break;
      case 'Low':
        priorityColor = '#3ADD5E';
        break;
      default:
        priorityColor = 'black';
        break;
    }

    switch (req.request_status) {
      case 'New Request':
        statusBgColor = '#F0F9FF';
        statusTextColor = '#3EAEFE';
        break;
      case 'Approved':
        statusBgColor = '#EFFCF2';
        statusTextColor = '#3ADD5E';
        break;
      case 'Rejected':
        statusBgColor = '#FFF0F0';
        statusTextColor = '#FE3E3E';
        break;
      case 'PO Created':
        statusBgColor = '#FFFAE9';
        statusTextColor = '#FCC216';
        break;
      case 'Material Received':
        statusBgColor = '#EFFCF2';
        statusTextColor = '#3ADD5E';
        break;
      case 'Reconcile':
        statusBgColor = '#FFF0F0';
        statusTextColor = '#FE3E3E';
        break;
      case 'Partially Received':
        statusBgColor = '#FFF0E5';
        statusTextColor = '#FB8C3C';
        break;
      case 'Payment Done':
        statusBgColor = '#FBF0FF';
        statusTextColor = '#CC3EFE';
        break;
      case 'Closed':
        statusBgColor = '#F2F2F2';
        statusTextColor = '#0E0E0E';
        break;
      default:
        statusBgColor = '#F2F2F2';
        statusTextColor = '#0E0E0E';
        break;
    }

    const status =
      req.request_status === 'PO Created'
        ? 'Material Out For Delivery'
        : req.request_status;

    async function showOrderDets(id) {
      axiosClientInterceptors
        .get(`/getrequestsbyid?_id=${id}`)
        .then(response => {
          // console.log(
          //   'response.data.Requests.status',
          //   response.data.Requests.request_status.at(-1).status,
          // );
          setShowLoader(false);
          const navData = {
            projectStage: projStage,
            status: response.data.Requests.current_status,
            invoiceImgs: response.data.Requests.invoice,
            orderDets: response.data.Requests,
          };
          props.navigation.navigate('OrderDetails', navData);
        })
        .catch(err => console.log(err));
    }

    return (
      <TouchableOpacity
        activeOpacity={0.6}
        style={styles.requestCard}
        title="orderMaterials"
        onPress={() => {
          setShowLoader(true);
          showOrderDets(req._id);
        }}>
        <View style={{flexDirection: 'row', width: '100%'}}>
          <Text style={styles.cardText}>{req.request_id}</Text>
          <Text style={[styles.cardText, {color: priorityColor}]}>
            {req.priority} Priority
          </Text>
          <Text style={[styles.cardText, {borderRightWidth: 0}]}>
            Due On {req.due_date}
          </Text>
        </View>
        <Text
          style={{
            width: '100%',
            fontFamily: 'Gilroy-SemiBold',
            fontSize: 17,
          }}>
          {req.material_category}
        </Text>
        <View
          style={{
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <View>
            <Text
              style={[
                styles.statusBox,
                {backgroundColor: statusBgColor, color: statusTextColor},
              ]}>
              {status}
            </Text>
          </View>
          <View style={styles.orderMaterials}>
            <Image
              style={styles.rightBtn}
              source={require('../../assets/images/RightBtn.png')}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  }

  let healthColor

  switch (projectDetails.project_health) {
    case 'New Project':
      healthColor = '#E33EFE';
      break;
    case 'Handover':
      healthColor = '#3ADD5E';
      break;
    case 'Progressing':
      healthColor = '#3EE7FE';
      break;
    case 'Sub-Critical':
      healthColor = '#FCC314';
      break;
    case 'Critical':
      healthColor = '#FC8314';
      break;
    case 'Super Critical':
      healthColor = '#FE3E3E';
      break;

    default:
      healthColor = 'black';
      break;
  }

  return (
    <SafeAreaView style={{backgroundColor: 'white', flex: 1}}>
      <ScrollView
        ref={scrollRef}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }>
        <View style={styles.card}>
          <LinearGradient
            start={{x: 0, y: 1}}
            end={{x: 1, y: 0}}
            colors={['#595959', '#0E0E0E']}
            style={[styles.cardRows, styles.gradientHeader]}>
            <Text style={[styles.infoText, {color: 'white'}]}>
              Project Name:
            </Text>
            <Text style={[styles.infoText, {color: 'white'}]}>
              {projectDetails.project_name}
            </Text>
          </LinearGradient>
          <View style={styles.cardRows}>
            <Text style={styles.lableText}>Project Health:</Text>
            <Text style={[styles.infoText, {color: healthColor}]}>
              {projectDetails.project_health}
            </Text>
          </View>
          <View style={styles.cardRows}>
            <Text style={styles.lableText}>Project Stage:</Text>
            <Text style={styles.infoText}>{projStage ? projStage : '-'}</Text>
          </View>
          <View style={styles.cardRows}>
            <Text style={styles.lableText}>Package:</Text>
            <Text style={styles.infoText}>{projectDetails.package}</Text>
          </View>
          {viewMore ? (
            <View>
              <View style={styles.cardRows}>
                <Text style={styles.lableText}>SBUA:</Text>
                <Text style={styles.infoText}>
                  {String(projectDetails.sbua)} Sq.ft
                </Text>
              </View>
              {projectDetails.bua && (
                <View style={styles.cardRows}>
                  <Text style={styles.lableText}>BUA:</Text>
                  <Text style={styles.infoText}>
                    {String(projectDetails.bua)} Sq.ft
                  </Text>
                </View>
              )}
              <View style={styles.cardRows}>
                <Text style={styles.lableText}>Style of Home:</Text>
                <Text style={styles.infoText}>
                  {projectDetails.style_of_home}
                </Text>
              </View>
              <View style={styles.cardRows}>
                <Text style={styles.lableText}>Construction Start Date:</Text>
                <Text style={styles.infoText}>
                  {projectDetails.construction_startdate}
                  {/* {projectDetails.construction_startdate
                    ? moment(projectDetails.construction_startdate).format(
                        'DD-MM-YYYY',
                      )
                    : '-'} */}
                </Text>
              </View>
              {projectDetails.construction_enddate !== undefined && (
                <View style={styles.cardRows}>
                  <Text style={styles.lableText}>Construction End Date:</Text>
                  <Text style={styles.infoText}>
                    {projectDetails.construction_enddate}
                    {/* {projectDetails.construction_enddate
                      ? moment(projectDetails.construction_enddate).format(
                          'DD-MM-YYYY',
                        )
                      : '-'} */}
                  </Text>
                </View>
              )}
              <View style={styles.cardRows}>
                <Text style={styles.lableText}>Project Manager:</Text>
                <Text style={styles.infoText}>
                  {projectDetails.project_manager}
                </Text>
              </View>
              <View style={styles.cardRows}>
                <Text style={styles.lableText}>Team Lead:</Text>
                <Text style={styles.infoText}>{projectDetails.team_lead}</Text>
              </View>
              <View style={styles.cardRows}>
                <Text style={styles.lableText}>Site Engineer:</Text>
                <Text style={styles.infoText}>
                  {projectDetails.site_engineer}
                </Text>
              </View>
              <View style={styles.cardRows}>
                <Text style={styles.lableText}>Architect:</Text>
                <Text style={styles.infoText}>{projectDetails.architect}</Text>
              </View>
              <View style={styles.cardRows}>
                <Text style={styles.lableText}>Site Location:</Text>
                <Text style={styles.infoText}>{projectDetails.location}</Text>
              </View>
              <View style={[styles.cardRows, {flexDirection: 'column'}]}>
                <Text style={styles.lableText}>Site Full Location</Text>
                <Text
                  style={{
                    fontFamily: 'Gilroy-Medium',
                    fontSize: 14,
                    marginTop: 10,
                  }}>
                  {projectDetails.site_full_address}
                </Text>
              </View>
              <View
                style={[
                  styles.cardRows,
                  {
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  },
                ]}>
                <View>
                  <Text style={styles.noOf}>No. Of Floors</Text>
                  <Text style={[styles.infoText, {fontSize: 16.5}]}>
                    G+{projectDetails.no_of_floors}
                  </Text>
                </View>
                <View>
                  <Text style={styles.noOf}>No. Of Bedrooms</Text>
                  <Text style={[styles.infoText, {fontSize: 16.5}]}>
                    {projectDetails.no_of_bedrooms} Bedrooms
                  </Text>
                </View>
                <View>
                  <Text style={styles.noOf}>No. Of Bathrooms</Text>
                  <Text style={[styles.infoText, {fontSize: 16.5}]}>
                    {projectDetails.no_of_bathrooms} Bathrooms
                  </Text>
                </View>
              </View>

              <TouchableOpacity
                style={[
                  styles.cardRows,
                  {justifyContent: 'center', borderBottomWidth: 0},
                ]}
                onPress={() => (
                  setViewMore(false),
                  scrollRef.current?.scrollTo({
                    y: 0,
                    animated: false,
                  })
                )}>
                <Text style={styles.vueMoreLess}>
                  View Less
                </Text>
              </TouchableOpacity>
            </View>
          ) : (
            <TouchableOpacity
              style={[
                styles.cardRows,
                {justifyContent: 'center', borderBottomWidth: 0},
              ]}
              onPress={() => setViewMore(true)}>
              <Text style={styles.vueMoreLess}>
                View More
              </Text>
            </TouchableOpacity>
          )}
        </View>
        <View style={styles.parentVue}>
          <View style={styles.requestsHeader}>
            <Text
              style={{
                fontSize: 18,
                marginTop: 10,
                marginBottom: 20,
                fontFamily: 'Gilroy-Bold',
              }}>
              Recent Requests
            </Text>
            {projectDetails._id && (
              <LinearGradient
                start={{x: 0, y: 1}}
                end={{x: 1, y: 0}}
                colors={['#FFE185', '#F6BA00']}
                style={styles.buttons}>
                <TouchableOpacity
                  style={{flexDirection: 'row', alignItems: 'center'}}
                  onPress={() =>
                    props.navigation.navigate('OrderMaterials', {
                      projectId: projectDetails.project_id,
                      projectName: projectDetails.project_name,
                      projectStage: projStage,
                      userName: Config.username,
                    })
                  }>
                  <Text
                    style={[
                      styles.infoText,
                      {
                        fontFamily: 'Gilroy-SemiBold',
                        fontSize: 14,
                        marginLeft: 5,
                      },
                    ]}>
                    Order Material
                  </Text>
                </TouchableOpacity>
              </LinearGradient>
            )}
          </View>

          <View style={{width: windowWidth}}>
            <TouchableOpacity
              style={styles.viewAllVue}
              activeOpacity={1}
              onPress={() =>
                projectDetails._id &&
                props.navigation.navigate('AllRequests', {
                  data: requests,
                  projectId: props.route.params.projectId,
                  project_stage: projStage,
                })
              }>
              <Text style={styles.viewAllTxt}>
                Showing{' '}
                <Text style={{color: 'black'}}>
                  {noOfReq <= 3 ? noOfReq : 4}
                </Text>{' '}
                out of <Text style={{color: 'black'}}>{noOfReq}</Text>
              </Text>
              <Text style={[styles.viewAllTxt, {color: 'black'}]}>
                View all
              </Text>
            </TouchableOpacity>
          </View>

          {requests.slice(0, 4).map(req => {
            return requestCard(req);
          })}

          {!showLoader && noOfReq === 0 && (
            <Image
              style={styles.noData}
              source={require('../../assets/images/noReq.png')}
            />
          )}
        </View>
      </ScrollView>
      {showLoader && (
        <ActivityIndicator size="large" color="#ffcd30" style={styles.loader} />
      )}
    </SafeAreaView>
  );
}

export default ProjectDetails;

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  card: {
    marginTop: 25,
    marginHorizontal: 15,
    backgroundColor: '#F8F8F8',
    borderRadius: 5,
  },
  cardRows: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomColor: '#EAEAEA',
    borderBottomWidth: 1,
    paddingTop: 20,
    paddingBottom: 20,
    marginHorizontal: 20,
  },
  lableText: {
    color: '#a1a1a1',
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
  },
  cardText: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 13,
    borderRightWidth: 0.8,
    borderColor: 'black',
    paddingRight: 8,
    marginRight: 8,
  },
  infoText: {
    color: 'black',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 16,
  },
  noOf: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#969696',
  },
  buttons: {
    width: 137,
    height: 38,
    borderRadius: 3,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 15,
  },
  rightBtn: {
    height: 20,
    width: 20,
    padding: 3,
  },
  requestCard: {
    justifyContent: 'space-between',
    width: windowWidth / 1.1,
    minHeight: 135,
    marginHorizontal: 20,
    marginVertical: 10,
    padding: 18,
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: '#FAFAFA',
  },
  status: {
    padding: 8,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 2,
  },
  loader: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: windowHeight / 2,
  },
  statusBox: {
    color: 'black',
    fontFamily: 'Gilroy-SemiBold',
    paddingVertical: 10,
    paddingHorizontal: 14,
    backgroundColor: '#EFFCF2',
    color: '#3ADD5E',
  },
  searchIcon: {
    color: 'black',
    fontSize: 30,
    margin: 10,
  },
  health: {
    paddingVertical: 10,
    paddingHorizontal: 14,
    borderRadius: 3,
    justifyContent: 'center',
    maxWidth: windowWidth / 3.4,
  },
  modalFilterView: {
    backgroundColor: 'white',
    // borderRadius: 20,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 25,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: windowWidth,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: windowWidth / 1.1,
  },
  modalText: {
    marginBottom: 25,
    textAlign: 'center',
    color: 'black',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  centeredFilterView: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    // paddingTop: windowHeight / 2,
    marginTop: windowHeight / 4,
  },
  modalButton: {
    // backgroundColor: '#ff4040',
    marginTop: 25,
    height: 45,
    width: windowWidth / 3,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterHeader: {
    width: windowWidth / 1.1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 25,
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: '#DEDEDE',
  },
  filterElements: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: '#F6F6F6',
    justifyContent: 'center',
    borderRadius: 3,
    marginVertical: 10,
    marginHorizontal: 4.5,
  },
  filterElementsView: {
    width: windowWidth / 1.1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    borderBottomWidth: 1,
    borderColor: '#DEDEDE',
    paddingBottom: 10,
    marginBottom: 15,
  },
  button: {
    backgroundColor: '#ffffff',
    borderRadius: 3,
    borderWidth: 1,
    borderColor: '#EAEAEA',
    width: 137,
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filterBottom: {
    width: windowWidth,
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingVertical: 22,
    backgroundColor: 'white',
    elevation: 10,
  },
  modalInfoText: {
    color: 'black',
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    marginBottom: 10,
  },
  noData: {
    width: 291,
    height: 253,
    alignSelf: 'center',
    justifyContent: 'center',
    marginVertical: 50,
  },
  filterText: {
    color: 'black',
    fontFamily: 'Gilroy-Medium',
    fontSize: 16,
  },
  vueMoreLess: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
  },
  viewAllVue: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    marginHorizontal: 16,
    marginTop: 5,
    marginBottom: -10,
    borderTopWidth: 1,
    borderColor: '#E7E7E7',
  },
  viewAllTxt: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 13,
    color: '#909090',
  },
  parentVue: {
    alignItems: 'center',
    paddingBottom: 25,
    flex: 1,
  },
  gradientHeader: {
    backgroundColor: '#ffcd30',
    marginHorizontal: 0,
    paddingHorizontal: 20,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  requestsHeader: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 15,
    marginTop: 50,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
