import moment from 'moment';

function DaysLeft(props) {
  const currentDate = moment();
  const endDate = moment(props.endDate, 'DD/MM/YYYY');
  const days_difference = endDate.diff(currentDate, 'days');
  const retDays = isNaN(days_difference) ? '-' : days_difference;
  return retDays;
}

export default DaysLeft;
