import React, {useEffect, useState} from 'react';
import LinearGradient from 'react-native-web-linear-gradient';
import {
  ScrollView,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
import moment from 'moment';

function OrderDetails(props) {
  const [priorityColor, setPriorityColor] = useState('#969696');
  const statusReceived = ['PO Created', 'Partially Received'];

  const orderDetails = props.route.params.orderDets;

  useEffect(() => {
    const priorityColor = () => {
      switch (orderDetails?.priority) {
        case 'High':
        case 'high':
          return '#FE3E3E';
        case 'Medium':
        case 'medium':
          return '#FCC314';
        case 'Low':
        case 'low':
          return '#3ADD5E';
        default:
          return '#969696';
      }
    };
    setPriorityColor(priorityColor);
  }, []);

  const materialImgs = [
    'Material Received',
    'Partially Received',
    'Payment Done',
    'Close',
  ];

  function materialDetsTable(dets, imgs) {
    return (
      <View style={styles.row}>
        <View style={styles.thContainer}>
          <View style={styles.th}>
            <View style={[styles.thItems, {width: '50%'}]}>
              <Text style={styles.labels}>Material Name</Text>
              <Text style={styles.inputs}>{dets.material_name}</Text>
            </View>
            <View style={[styles.thItems, {width: '20%'}]}>
              <Text style={styles.labels}>Ordered</Text>
              <Text style={styles.inputs}>{dets.quantity}</Text>
            </View>
            <View style={[styles.thItems, {width: '20%'}]}>
              <Text style={styles.labels}>UOM</Text>
              <Text style={styles.inputs}>{dets.uom}</Text>
            </View>
          </View>
          <View style={styles.tb}>
            <View style={styles.tbInputs}>
              <Text style={styles.labels}>Material Received Photo</Text>

              <TouchableOpacity
                onPress={() =>
                  props.navigation.navigate('ImageGallery', {
                    images: imgs,
                  })
                }>
                <LinearGradient
                  colors={['#FFE185', '#F6BA00']}
                  start={{x: 0, y: 1}}
                  end={{x: 1, y: 0}}
                  style={[
                    styles.buttons,
                    {width: 115, height: 36, marginHorizontal: 0},
                  ]}>
                  <Text
                    style={{
                      fontSize: 13,
                      fontFamily: 'Gilroy-Medium',
                      color: 'black',
                    }}>
                    View {imgs.length} Images
                  </Text>
                </LinearGradient>
              </TouchableOpacity>
            </View>
            <View style={styles.tbInputs}>
              <Text style={styles.labels}>Total Qty Received</Text>
              <Text style={styles.inputs}>
                {dets.received ? dets.received : 0}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }

  function showInvoiceImagesUI(title, imgs) {
    return (
      <View style={styles.viewImgRow}>
        <Text style={styles.infoText}>{title} Images</Text>
        <TouchableOpacity
          onPress={() =>
            props.navigation.navigate('ImageGallery', {images: imgs})
          }>
          <LinearGradient
            colors={['#FFE185', '#F6BA00']}
            start={{x: 0, y: 1}}
            end={{x: 1, y: 0}}
            style={[
              styles.buttons,
              {width: 115, height: 36, marginHorizontal: 0},
            ]}>
            <Text
              style={{
                fontSize: 13,
                fontFamily: 'Gilroy-Medium',
                color: 'black',
              }}>
              View {imgs?.length} Images
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    );
  }

  function setStatusColors(status) {
    let statusBgColor;
    let statusTextColor;
    switch (status) {
      case 'New Request':
        statusBgColor = '#F0F9FF';
        statusTextColor = '#3EAEFE';
        break;
      case 'Approved':
        statusBgColor = '#EFFCF2';
        statusTextColor = '#3ADD5E';
        break;
      case 'Rejected':
        statusBgColor = '#FFF0F0';
        statusTextColor = '#FE3E3E';
        break;
      case 'PO Created':
        statusBgColor = '#FFFAE9';
        statusTextColor = '#FCC216';
        break;
      case 'Material Received':
        statusBgColor = '#EFFCF2';
        statusTextColor = '#3ADD5E';
        break;
      case 'Reconcile':
        statusBgColor = '#FFF0F0';
        statusTextColor = '#FE3E3E';
        break;
      case 'Partially Received':
        statusBgColor = '#FFF0E5';
        statusTextColor = '#FB8C3C';
        break;
      case 'Payment Done':
        statusBgColor = '#FBF0FF';
        statusTextColor = '#CC3EFE';
        break;
      case 'Closed':
        statusBgColor = '#F2F2F2';
        statusTextColor = '#0E0E0E';
        break;
      default:
        statusBgColor = '#F2F2F2';
        statusTextColor = '#0E0E0E';
        break;
    }
    return {backgroundColor: statusBgColor, color: statusTextColor};
  }

  const bgtextColor = setStatusColors(props.route.params.status);

  return (
    <SafeAreaView style={styles.parentBg}>
      <ScrollView style={{backgroundColor: '#F8F8F8'}}>
        <View style={styles.cardView}>
          <View style={[styles.statusHeader, bgtextColor]}>
            <Text style={[styles.infoText, bgtextColor, {fontSize: 14}]}>
              {props.route.params.status === 'PO Created'
                ? 'Material Out For Delivery'
                : props.route.params.status}
            </Text>
          </View>
          {props.route.params.status === 'Rejected' && (
            <View style={{marginBottom: 25}}>
              <Text style={styles.rejectReasHeader}>Reject Reason</Text>
              <Text style={styles.rejectReasTxt}>{orderDetails?.note}</Text>
            </View>
          )}
          <View style={styles.projectInfo}>
            <View style={{width: '50%'}}>
              <Text style={[styles.text, styles.lableText]}>Project Name</Text>
              <Text style={[styles.infoText, {paddingTop: 2, marginBottom: 0}]}>
                {orderDetails?.project_name}
              </Text>
            </View>
            <View style={{width: '45%'}}>
              <Text style={[styles.text, styles.lableText]}>Project Stage</Text>
              <Text style={[styles.infoText, {paddingTop: 2, marginBottom: 0}]}>
                {props.route.params.projectStage}
              </Text>
            </View>
          </View>
          <View style={styles.matCatStat}>
            <View style={styles.matCatDiv}>
              <Text style={styles.lableText}>Material Category</Text>
              <Text style={styles.infoText}>
                {orderDetails?.material_category}
              </Text>
            </View>
            <View style={styles.matStatDiv}>
              <Text style={styles.lableText}>Priority</Text>
              <Text style={[styles.infoText, {color: priorityColor}]}>
                {orderDetails?.priority}
              </Text>
            </View>
          </View>

          {materialImgs.includes(props.route.params.status) ? (
            <View>
              <View style={styles.matCatStat}>
                <View style={styles.matCatDiv}>
                  <Text style={styles.lableText}>Invoice No.</Text>
                  <Text style={styles.infoText}>
                    {orderDetails?.invoice_number}
                  </Text>
                </View>
                <View style={styles.matStatDiv}>
                  <Text style={styles.lableText}>Total Amount</Text>
                  <Text style={styles.infoText}>
                    {orderDetails?.invoice_amount}
                  </Text>
                </View>
              </View>
              <View style={[styles.matCatStat, {flexDirection: 'column'}]}>
                {showInvoiceImagesUI('Invoice', props.route.params.invoiceImgs)}
                {orderDetails?.materials.map(material => {
                  return materialDetsTable(
                    material,
                    material.received_material,
                  );
                })}
              </View>
            </View>
          ) : (
            <View style={styles.materialTable}>
              <View style={styles.materialTableLabels}>
                <Text style={[styles.matTableHeadTxt, {width: '65%'}]}>
                  Material Name
                </Text>
                <Text
                  style={[
                    styles.matTableHeadTxt,
                    {width: '10%', textAlign: 'center'},
                  ]}>
                  Qty
                </Text>
                <Text
                  style={[
                    styles.matTableHeadTxt,
                    {width: '20%', textAlign: 'right'},
                  ]}>
                  UOM
                </Text>
              </View>
              {orderDetails?.materials.map(matData => (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding: 15,
                  }}>
                  <Text style={[styles.infoText, {width: '65%'}]}>
                    {matData.material_name}
                  </Text>
                  <Text
                    style={[
                      styles.infoText,
                      {width: '10%', textAlign: 'center'},
                    ]}>
                    {matData.quantity}
                  </Text>
                  <Text
                    style={[
                      styles.infoText,
                      {width: '20%', textAlign: 'right'},
                    ]}>
                    {matData.uom}
                  </Text>
                </View>
              ))}
            </View>
          )}

          <View style={styles.dateAndApprover}>
            <View style={styles.dueDate}>
              <Text style={styles.lableText}>Due Date</Text>
              <Text style={styles.infoText}>
                {orderDetails?.due_date}
                {/* {orderDetails?.due_date} */}
              </Text>
            </View>
            <View style={styles.approver}>
              <Text style={styles.lableText}>Approver</Text>
              <Text style={styles.infoText}>{orderDetails?.approved_by}</Text>
            </View>
          </View>
          <View style={styles.matDescPurp}>
            <Text style={styles.lableText}>Material Description</Text>
            <Text
              style={[
                styles.infoText,
                {fontFamily: 'Gilroy-Medium', marginBottom: 25},
              ]}>
              {orderDetails?.material_description ? (
                orderDetails?.material_description
              ) : (
                <Text style={styles.descriptionPh}>
                  No description provided
                </Text>
              )}
            </Text>
          </View>
          <View style={[styles.matDescPurp, {borderBottomWidth: 0}]}>
            <Text style={styles.lableText}>Purpose</Text>
            <Text style={[styles.infoText, {fontFamily: 'Gilroy-Medium'}]}>
              {orderDetails?.purpose}
            </Text>
          </View>
        </View>
        <View style={styles.btmBtnsVue}>
          <TouchableOpacity
            onPress={() =>
              props.navigation.navigate('TrackOrder', {
                tracking: orderDetails.request_status,
              })
            }
            style={[styles.buttons, styles.trackOrderBtn]}>
            <Text style={[styles.infoText, {fontSize: 15}]}>Track Order</Text>
          </TouchableOpacity>
          {statusReceived.includes(props.route.params.status) && (
            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate('MaterialReceived', {
                  materials: orderDetails?.materials,
                  _id: orderDetails?._id,
                });
              }}
              style={[styles.buttons, {width: '48%', margin: 0}]}>
              <LinearGradient
                colors={['#FFE185', '#F6BA00']}
                start={{x: 0, y: 1}}
                end={{x: 1, y: 0}}
                style={[styles.buttons, {margin: 0, width: '100%'}]}>
                <Text style={[styles.infoText, {fontSize: 15}]}>
                  Order Received
                </Text>
              </LinearGradient>
            </TouchableOpacity>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}
export default OrderDetails;

const windowWidth = Dimensions.get('window').width;

const styles = StyleSheet.create({
  cardView: {
    backgroundColor: 'white',
    width: windowWidth / 1.1,
    padding: 20,
    borderRadius: 5,
    alignSelf: 'center',
    marginBottom: 25,
    marginTop: 5,
  },
  modalText: {
    marginBottom: 25,
    textAlign: 'center',
    color: 'black',
  },
  scrollViewStyle: {padding: 0},
  statusHeader: {
    height: 36,
    marginBottom: 20,
    width: '100%',
    alignItems: 'center',
    backgroundColor: '#FFFBED',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 3,
  },
  lableText: {
    fontFamily: 'Gilroy-Medium',
    color: '#a1a1a1',
    fontSize: 13,
    marginBottom: 5,
  },
  infoText: {
    color: 'black',
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 15,
  },
  projectInfo: {
    backgroundColor: '#FFF5D6',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 3,
    marginBottom: 35,
    width: '100%',
  },
  ProjectInfoDivs: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  matCatStat: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 25,
    borderBottomWidth: 1,
    borderBottomColor: '#96969696',
    borderStyle: 'dashed',
    marginBottom: 25,
    width: '100%',
  },
  matCatDiv: {
    width: '60%',
  },
  matStatDiv: {
    width: '35%',
  },
  materialTableLabels: {
    padding: 15,
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    height: 60,
    alignItems: 'center',
    backgroundColor: '#F2F2F2',
  },
  materialTable: {
    borderWidth: 1,
    borderColor: '#F2F2F2',
    marginBottom: 35,
  },
  dateAndApprover: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 35,
    borderBottomWidth: 1,
    borderBottomColor: '#96969696',
    borderStyle: 'dashed',
    marginBottom: 25,
  },
  dueDate: {
    width: '50%',
  },
  approver: {
    width: '40%',
  },
  matDescPurp: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 5,
    borderBottomWidth: 1,
    borderBottomColor: '#96969696',
    borderStyle: 'dashed',
    marginBottom: 25,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  buttons: {
    backgroundColor: '#ffd142',
    width: windowWidth / 2.8,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
  },
  viewImgRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    marginBottom: 20,
  },

  // Material Detail tables
  row: {
    flexDirection: 'row',
    width: windowWidth / 1.22,
    justifyContent: 'space-between',
  },
  thContainer: {
    borderWidth: 1,
    borderColor: '#F5F5F5',
    width: '100%',
    borderRadius: 5,
    marginVertical: 5,
  },
  th: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    padding: 15,
  },
  tb: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15,
  },
  tbInputs: {alignSelf: 'flex-start'},
  labels: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 12,
    color: '#969696',
    marginBottom: 8,
  },
  inputs: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 15,
    color: '#221E1F',
    width: '100%',
  },
  rejectReasHeader: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 13,
    color: '#969696',
    marginBottom: 8,
  },
  rejectReasTxt: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    lineHeight: 20,
  },
  btmBtnsVue: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: -15,
    width: windowWidth,
    padding: 15,
    marginBottom: 25,
  },
  trackOrderBtn: {
    backgroundColor: '#F8F8F8',
    borderWidth: 1,
    borderColor: '#DBDBDB',
    width: '48%',
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  descriptionPh: {
    color: '#dedede',
    fontSize: 12,
  },
  matTableHeadTxt: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 13,
  },
});
