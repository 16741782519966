import React, {useEffect, useState} from 'react';
import closeBtn from '../../assets/images/popupclose.png';
import {
  ScrollView,
  SafeAreaView,
  Modal,
  StyleSheet,
  Text,
  View,
  Image,
  ImageBackground,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import Config from '../BasicComponents/Config';

function ImageGallery(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const [popupImg, setPopupImg] = useState();

  return (
    <ScrollView
      style={{
        backgroundColor: 'white',
      }}>
      <Modal
        animationType="fade"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(false);
        }}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Image style={styles.popupImage} source={{uri: `${popupImg}`}} />
          </View>
          <TouchableOpacity
            style={styles.closeBtn}
            onPress={() => setModalVisible(false)}>
            <Image style={styles.closeBtn} source={closeBtn} />
          </TouchableOpacity>
        </View>
      </Modal>
      <View style={styles.parentDiv}>
        {props.route.params.images.map(img => {
          const image = `${Config.REACT_APP_IMAGES_URL}${img}`;
          const pdfSrc =
          Config.REACT_DATA_API_URL +
          Config.REACT_APP_ENVIRONMENT +
            '/view-file?file=' +
            img;
          const source = {
            uri: pdfSrc,
            cache: true,
          };
          return (
            <TouchableOpacity
              style={styles.imgDiv}
              onPress={() => (setModalVisible(true), setPopupImg(source))}>
              {/* <Text>{img[0]}</Text> */}
              <ImageBackground
                style={styles.images}
                source={{uri: `${image}`}}
              />
            </TouchableOpacity>
          );
        })}
        {!(props.route.params.images.length % 2 === 0) && (
          <View style={[styles.images, {backgroundColor: 'white'}]} />
        )}
      </View>
    </ScrollView>
  );
}

export default ImageGallery;

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  parentDiv: {
    width: windowWidth,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: 'white',
    flexWrap: 'wrap',
    paddingHorizontal: 5,
  },
  images: {
    backgroundColor: 'lightgrey',
    width: windowWidth / 2.2,
    height: 150,
    marginVertical: 7,
    borderRadius: 4,
  },
  popupImage: {
    width: '95%',
    height: '75%',
    alignSelf: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: windowWidth,
    paddingBottom: 25,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  buttonClose: {
    backgroundColor: '#2196F3',
  },
  modalView: {
    marginBottom: 20,
    backgroundColor: 'transparent',
    borderRadius: 10,
    alignItems: 'center',
    width: windowWidth / 1.1,
  },
  closeBtn: {
    height: 54,
    width: 54,
    marginTop: 10,
  },
});
